import React, { useContext, useEffect, useState } from 'react'
import '../../assets/scss/admin-projects.scss'
import * as analytics from '../../services/analyticsService'
import { FirebaseAuthContext } from '../../contexts/AuthProvider'
import { useCollection } from 'react-firebase-hooks/firestore'
import { firestore } from 'firebase/app'
import { showToast } from '../../config/toast'
import Table, { ColumnData, RowData } from '../../components/table'
import LoadingSplash from '../../components/loading-splash'

const projectsColumns: ColumnData[] = [
  {
    id: 'logo',
    title: 'Logo',
    type: 'Image',
    sortable: false
  },
  {
    id: 'name',
    title: 'Name',
    type: 'string',
    sortable: true
  },
  {
    id: 'project',
    title: 'Project ID',
    type: 'string',
    sortable: true
  },
  {
    id: 'abbreviation',
    title: 'Abbreviation',
    type: 'string',
    sortable: true
  },
  {
    id: 'croEnabled',
    title: 'CRO',
    type: 'Boolean',
    sortable: true
  },
  {
    id: 'dataEnabled',
    title: 'Data',
    type: 'Boolean',
    sortable: true
  },
  {
    id: 'emEnabled',
    title: 'Email',
    type: 'Boolean',
    sortable: true
  },
  {
    id: 'seoEnabled',
    title: 'SEO',
    type: 'Boolean',
    sortable: true
  }
]

/**
 * Admin (projects) admin view
 * @TODO: Paginate
 * @TODO: Link to edit modal
 * @TODO: New project modal
 * @TODO: Service line filtering
 * @TODO: Proportional logos
 * @TODO: Reflect changes in TW?
 */
export const Projects: React.FC = () => {
  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'admin-projects'
  })
  analytics.setCurrentScreen('admin-projects')
  const { currentUser } = useContext(FirebaseAuthContext)
  if (currentUser?.uid) analytics.setUserID(currentUser.uid)

  const [values, loading, error] = useCollection(firestore().collection('projects'))
  const [projectRows, setProjectRows] = useState<RowData[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (error) {
      setIsLoading(false)
      showToast(JSON.stringify(error.message), 'error')
    } else if (loading) {
      setIsLoading(true)
    } else if (values) {
      const projectRowsMapped: RowData[] = []
      values.forEach((doc: any) => {
        const project = doc.data()
        projectRowsMapped.push({
          data: {
            logo: project.logo,
            name: project.name,
            project: project.project,
            abbreviation: project.abbreviation,
            croEnabled: project.croEnabled,
            dataEnabled: project.dataEnabled,
            emEnabled: project.emEnabled,
            seoEnabled: project.seoEnabled
          }
        })
      })
      setProjectRows(projectRowsMapped)
      setIsLoading(false)
    }
  }, [error, loading, values])

  return <div className='users view'>
    <h3>Projects</h3>
    {
      isLoading
        ? <LoadingSplash caption={'Loading project list'}/>
        : <Table columns={projectsColumns} rows={projectRows} filters={[]}/>
    }
  </div>
}
