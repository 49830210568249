import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import * as serviceWorker from './serviceWorker'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/performance'
import 'firebase/analytics'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastConfig } from './config/toast'

// noinspection SpellCheckingInspection
const config = {
  apiKey: 'AIzaSyCMVgo916H18ZtV15ngsVRfgOJSMyxJwBI',
  authDomain: 'tixray.firebaseapp.com',
  databaseURL: 'https://tixray.firebaseio.com',
  projectId: 'tixray',
  storageBucket: 'tixray.appspot.com',
  messagingSenderId: '206818003756',
  appId: '1:206818003756:web:c331759d98e5f2d2f4fa89',
  measurementId: 'G-SZGMBDFQBG'
}

// Initialize Firebase
firebase.initializeApp(config)

// Initialize Performance Monitoring and get a reference to the service
const perf = firebase.performance()
perf.dataCollectionEnabled = true
perf.instrumentationEnabled = true

// Initialize Toast Container
toast.configure(toastConfig)

ReactDOM.render(
  <App/>,
  document.getElementById('root')
)

// noinspection JSIgnoredPromiseFromCall
serviceWorker.unregister()
