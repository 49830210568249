import React, { SyntheticEvent, useEffect, useState } from 'react'
import '../assets/scss/table.scss'

import PercentageBar from '../components/percentage-bar'

export interface ColumnData {
  id: string
  title: string
  type: 'Link' | 'Status' | 'Date' | 'Chart' | 'Image' | 'Boolean' | 'Dom' | string
  sortable: boolean
}

export interface RowData {
  filters?: {
    [key: string]: number
  }
  data: {
    [key: string]: any
  }
}

export interface TableFilter {
  [key: string]: number
}

// Filters:  for (var i = 0; i<16; i++){j=(i===0)?15:i;console.log(`${i}\t${(j>>3)%2}\t${(j>>2)%2}\t${(j>>1)%2}\t${(j>>0)%2}`)}

export interface TableProps {
  columns: ColumnData[]
  rows: RowData[]
  filters: TableFilter[]
}

const Table: React.FC<TableProps> = (props) => {
  const [columns, setColumns] = useState<ColumnData[]>(props.columns)
  const [rows, setRows] = useState<RowData[]>(props.rows)
  const [sortColumn, setSortColumn] = useState<string>(props.columns?.length > 0 ? props.columns[0].id : '')
  const [sortReverse, setSortReverse] = useState<boolean>(false)

  useEffect(() => {
    setColumns(props.columns)
    setRows(props.rows)
  }, [props.columns, props.rows])

  const renderRow = (row: RowData, i: number) => {
    return (
      <div className='table-row' key={'row' + i}>
        {columns.map((el) => {
          switch (el.type) {
            case 'Link':
              return <div className='table-cell' key={el.id + i}>
                <a target="_blank" rel="noopener noreferrer" href={row.data[el.id]} key={el.id + i}>View Report<i
                  className="fas fa-external-link-alt"/></a>
              </div>
            case 'Status':
              return <div className='table-cell' key={el.id + i}>
                <span
                  className={`test-status ${row.data[el.id].toLowerCase()}-test`}
                  key={el.id + i}>{row.data[el.id]}</span>
              </div>
            case 'Date':
              return <div className='table-cell' key={el.id + i}>
                {new Date(row.data[el.id]).toDateString()
                  .replace(/^\D{3}\s(\D{3})(?:\s0|\s)(\d+)\s(\d{4})/g,
                    '$1 $2, $3')}
              </div>
            case 'Chart':
              return <div className='table-cell' key={el.id + i}>
                <PercentageBar percentage={Number(row.data[el.id])}/>
              </div>
            case 'Boolean':
              return <div className='table-cell' key={el.id + i}>
                {row.data[el.id] ? <i className='fa fa-check'/> : <i className='fa fa-times'/>}
              </div>
            case 'Image':
              return <div className='table-cell' key={el.id + i}>
                <img alt={''} className='table-image' src={row.data[el.id]}/>
              </div>
            case 'Dom':
              return row.data[el.id]
            default:
              return <div className='table-cell' key={el.id + i}>{row.data[el.id]}</div>
          }
        })}
      </div>
    )
  }

  // TODO: Sort based on non-string content (ie. datetimes and datetimedeltas)
  const sort = (e: SyntheticEvent, id: string) => {
    if (sortColumn === id) {
      // reverse the index if active
      const newReverse = !sortReverse
      const newRows = rows.sort((a: RowData, b: RowData): number => {
        if ((a.data[id]?.toString().toLocaleLowerCase() || '') < b.data[id]?.toString().toLocaleLowerCase()) return -1
        if ((b.data[id]?.toString().toLocaleLowerCase() || '') < a.data[id]?.toString().toLocaleLowerCase()) return 1
        return 0
      })
      if (newReverse) newRows.reverse()
      setRows(newRows)
      setSortReverse(newReverse)
    } else {
      setSortColumn(id)
      setSortReverse(false)
      setRows(rows.sort((a: RowData, b: RowData): number => {
        if (a.data[id] < b.data[id]) return -1
        if (b.data[id] < a.data[id]) return 1
        return 0
      }))
    }
  }

  return (
    <div className='table'>
      <div className='table-contents'>
        <div className='table-head table-row'>
          {columns.map((el: ColumnData) => {
            let className = el.sortable ? 'table-cell sortable' : 'table-cell'
            if (sortColumn === el.id) {
              className += ' active'
              if (sortReverse) {
                className += ' reversed'
              }
            }
            return <div
              className={className}
              onClick={el.sortable ? (e) => sort(e, el.id) : () => {}}
              key={el.id}>{el.title}
            </div>
          })}
        </div>
        {
          rows.map((row: RowData, i: number) => {
            return renderRow(row, i)
          })
        }
      </div>
    </div>
  )
}

export default Table
