import React, { useState } from 'react'
import '../assets/scss/login.scss'
import * as analytics from '../services/analyticsService'
import { FirebaseAuthContext } from '../contexts/AuthProvider'

/**
 * @todo move these methods from props into the context
 */
type LoginProps = {
  loginWithGoogle: (e: any) => void
  loginWithPassword: (email: string, password: string) => void
}

/**
 * Login login view
 */
export const LoginView: React.FC<LoginProps> = (props) => {
  /**
   * Set state and import the password reset function from auth context
   */
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [remember, setRemember] = useState<boolean>(false)
  const [emailModal, setEmailModal] = useState<boolean>(false)
  const { sendPasswordReset } = React.useContext(FirebaseAuthContext)

  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'login'
  })
  analytics.setCurrentScreen('login')

  return (
    <div className='login'>
      {emailModal
        ? <div className='login-content'>
          <h4 className='back' onClick={() => { setEmailModal(false) }}>Back</h4>
          <div className='logo' />
          <h3>Enter your email to reset your password</h3>
          <input
            name='email'
            placeholder={'Email Address'}
            inputMode={'email'}
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
          <button
            onClick={() => {
              sendPasswordReset(email)
                .finally(() => { setEmailModal(false) })
            }}>
            Reset
          </button>
        </div>
        : <div className='login-content'>
          <div className='logo' />
          <h3>Welcome back! Please login to your account.</h3>
          <input
            name='email'
            placeholder={'Email Address'}
            inputMode={'email'}
            onChange={event => setEmail(event.target.value)}
          />
          <input
            name='password'
            value={password}
            placeholder={'Password'}
            type={'password'}
            onChange={event => setPassword(event.target.value)}
          />
          <div className='login-assistance'>
            <span className={`remember${remember ? ' checked' : ''}`} onClick={() => (setRemember(!remember))}>
              <input type={'checkbox'} defaultChecked={remember} />
              Remember me
            </span>
            <span className='forgot' onClick={() => { setEmailModal(true) }}>
              Forgot Password?
            </span>
          </div>
          <button onClick={() => { props.loginWithPassword(email, password) }}>login</button>
        </div>}
      <div className='terms-of-use'>
        <span>Terms of use.</span>
        <span> Privacy policy</span> |
        <span className='' onClick={props.loginWithGoogle}> Employee login</span>
      </div>
    </div>
  )
}

/* TODO: Loading while waiting for reset email toast */
