import React, { useContext, useState } from 'react'
import '../assets/scss/test-status.scss'
import Table from '../components/table'
import Filter from '../components/filter'
import { GetUxDevData, UxColumns } from '../services/uxService'
import * as analytics from '../services/analyticsService'
import { ProjectContext } from '../contexts/ProjectProvider'
import { FirebaseAuthContext } from '../contexts/AuthProvider'
import TestCounter from '../components/test-counter'

const UxStatusColumns = [
  UxColumns.name,
  UxColumns.startDate,
  UxColumns.status,
  UxColumns.testArea,
  UxColumns.percentCollected,
  UxColumns.reportLink
]

/**
 * Test Status dashboard view
 */
export const TestsView: React.FC = () => {
  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'test-status'
  })
  analytics.setCurrentScreen('test-status')
  const { currentUser } = useContext(FirebaseAuthContext)
  if (currentUser?.uid) analytics.setUserID(currentUser.uid)

  /**
   * Set a state for the filter
   */
  const [filterStatus, setFilterStatus] = useState<number>(0)
  const { projectDoc } = useContext(ProjectContext)

  const updateFilterStatus = (value: number) => { setFilterStatus(filterStatus + value) }

  return <div className='test-status view'>
    <h1>Test Status (8)</h1>
    <div className='view-content'>
      <Filter
        filterStatus={filterStatus}
        statusAction={updateFilterStatus}>
      </Filter>
      <div className='card'>
        <div className='card-content'>
          <Table columns={UxStatusColumns} rows={GetUxDevData()} filters={[]}/>
        </div>
      </div>
      <div className='card'>
        <div className='card-content'>
          <TestCounter></TestCounter>
        </div>
      </div>
    </div>
  </div>
}
