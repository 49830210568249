import React from 'react'
import { createBrowserHistory } from 'history'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import './assets/scss/app.scss'
import '@ionic/react/css/core.css'
import { FirebaseAuthProvider } from './contexts/AuthProvider'
import { indexRoutes, PrivateRoute } from './routes'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
// TODO: Kate commented out the iconic typography.css because it was overriding her css. 
// import '@ionic/react/css/typography.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

export default () => (
  <FirebaseAuthProvider>
    <Router history={createBrowserHistory()}>
      <Switch>
        {indexRoutes.map((prop, key) => {
          if (prop.redirect && prop.to) return <Redirect from={prop.path} to={prop.to} key={key}/>
          if (prop.requireAuth) return <PrivateRoute path={prop.path} component={prop.component} key={key}/>
          if (prop.path && prop.component) return <Route path={prop.path} component={prop.component} key={key}/>
          return <div key={key}/>
        })}
      </Switch>
    </Router>
  </FirebaseAuthProvider>
)
