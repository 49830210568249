import React, { useContext, useEffect, useState } from 'react'
import '../../assets/scss/admin-projects.scss'
import * as analytics from '../../services/analyticsService'
import { FirebaseAuthContext } from '../../contexts/AuthProvider'
import { useCollection } from 'react-firebase-hooks/firestore'
import { firestore } from 'firebase/app'
import { showToast } from '../../config/toast'
import Table, { ColumnData, RowData } from '../../components/table'
import LoadingSplash from '../../components/loading-splash'

const projectsColumns: ColumnData[] = [
  {
    id: 'logo',
    title: 'Logo',
    type: 'Image',
    sortable: false
  },
  {
    id: 'name',
    title: 'Name',
    type: 'string',
    sortable: true
  },
  {
    id: 'project',
    title: 'Project ID',
    type: 'string',
    sortable: true
  },
  {
    id: 'abbreviation',
    title: 'Abbreviation',
    type: 'string',
    sortable: true
  },
  {
    id: 'abbreviation',
    title: 'Abbreviation',
    type: 'string',
    sortable: true
  }
]

/**
 * Admin (xray) admin view
 * @TODO: add integration counts
 */
export const Xray: React.FC = () => {
  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'admin-xray'
  })
  analytics.setCurrentScreen('admin-xray')
  const { currentUser } = useContext(FirebaseAuthContext)
  if (currentUser?.uid) analytics.setUserID(currentUser.uid)

  const [values, loading, error] = useCollection(firestore().collection('projects'))
  const [projectRows, setProjectRows] = useState<RowData[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (error) {
      setIsLoading(false)
      showToast(JSON.stringify(error.message), 'error')
    } else if (loading) {
      setIsLoading(true)
    } else if (values) {
      setIsLoading(true)
      const projectRowsMapped: RowData[] = []
      values.forEach((doc: any) => {
        const project = doc.data()
        if (project.dataEnabled) {
          // Get the integration counts for the project
          projectRowsMapped.push({
            data: {
              logo: project.logo,
              name: project.name,
              project: project.project,
              abbreviation: project.abbreviation
            }
          })
        }
      })
      setProjectRows(projectRowsMapped)
      setIsLoading(false)
    }
  }, [loading, values, error])

  if (isLoading) {
    return <div className='users view'>
      <LoadingSplash caption={'Loading project list'}/>
    </div>
  } else {
    return <div className='users view'>
      <h3>Projects</h3>
      <Table columns={projectsColumns} rows={projectRows} filters={[]}/>
    </div>
  }
}
