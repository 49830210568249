import React, { useContext } from 'react'
import '../assets/scss/contact.scss'
import * as analytics from '../services/analyticsService'
import { ProjectContext } from '../contexts/ProjectProvider'
import { FirebaseAuthContext } from '../contexts/AuthProvider'

/**
 * Used if there is no photo on the trinity contact for the current project
 */
const backupImage = process.env.NODE_ENV === 'test' ? '' : require('../assets/img/trinity-branding/logo192.png')

/**
 * Contact Us dashboard view
 */
export const Contact: React.FC = () => {
  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'contact'
  })
  analytics.setCurrentScreen('contact')
  const { currentUser } = useContext(FirebaseAuthContext)
  if (currentUser?.uid) analytics.setUserID(currentUser.uid)

  /**
   * Get the project doc from the project provider
   */
  const { projectDoc } = React.useContext(ProjectContext)
  return <div className='contact view'>
    <h1>We're here to help.</h1>
    <h3>We'll get back to you within one business day!</h3>
    <div className='view-content'>
      <div className='card'>
        <div className='card-content'>
          <h4>Contact your {projectDoc.trinityContact?.role || 'account analyst'}:</h4>
          <div className='contact-analyst-container'>
            <img
              src={projectDoc.trinityContact?.photoUrl || backupImage}
              className='contact-img'
              alt={`${projectDoc.trinityContact?.firstName || ''} ${projectDoc.trinityContact?.lastName || ''}`}/>
            <div className='contact-info'>
              <h3
                className='contact-name'>{`${projectDoc.trinityContact?.firstName || 'Trinity'} ${projectDoc.trinityContact?.lastName || 'Employee'}`}</h3>
              <h3 className='contact-title'>{projectDoc.trinityContact?.title}</h3>
              <button>Message {projectDoc.trinityContact?.firstName || 'us'}</button>
            </div>
          </div>
          <div className='contact-trinity-container'>
            <h4>Give us a call:</h4>
            <div className='contact-phone'><i className="fas fa-phone-alt"/>
              {projectDoc.trinityContact?.phoneNumber || '1-866-535-8807 ext. 730'}</div>
            <div className='office-hours'>
              <span>Office hours: </span>{projectDoc.trinityContact?.officeHours || '8:00am - 5:00pm CT'}</div>
          </div>
        </div>
      </div>
      <div className='workplace-img'/>
    </div>
  </div>
}
