import React, { useContext } from 'react'
import HoursData from '../components/hours-data'
import Calendar from '../components/calendar'
import { Link } from 'react-router-dom'
import { GetUxDevData, UxColumns } from '../services/uxService'
import '../assets/scss/dashboard.scss'
import * as analytics from '../services/analyticsService'
import { ProjectContext } from '../contexts/ProjectProvider'
import { FirebaseAuthContext } from '../contexts/AuthProvider'
import Table from '../components/table'

const UxDashboardColumns = [
  UxColumns.name,
  UxColumns.startDate,
  UxColumns.testArea,
  UxColumns.percentCollected,
  UxColumns.reportLink
]

/**
 * Dashboard (home) dashboard view
 */
export const Dashboard: React.FC = () => {
  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'dashboard'
  })
  analytics.setCurrentScreen('dashboard')
  const { currentUser } = useContext(FirebaseAuthContext)
  if (currentUser?.uid) analytics.setUserID(currentUser.uid)

  const { projectDoc } = useContext(ProjectContext)

  return <div className='dashboard view'>
    <h1>Dashboard</h1>
    <div className='view-content'>
      <div className='card one-quarter'>
        <div className='card-content'>
          <h3>Hour Bank</h3>
          <HoursData/>
          <Link className={'view-all-link'} to='/app/hours'>View Full Report</Link>
        </div>
      </div>
      <div className='card three-quarters'>
        <div className='card-content'>
          <Calendar></Calendar>
        </div>
      </div>
    </div>
  </div>
}
