import React from 'react'
import '../assets/scss/percentage-bar.scss'

interface PercentageBarProps {
  percentage: number
}

const PercentageBar: React.FC<PercentageBarProps> = (props) => {
  return (
    <div className='progress-container'>
      <div className='percentage'>{props.percentage}%</div>
      <div className='progress_bar'>
        <div className='progress' style={{ width: `${(100 - props.percentage)}%` }}/>
      </div>
    </div>
  )
}

export default PercentageBar
