import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import '../../assets/scss/admin-hat-tool.scss'
import * as analytics from '../../services/analyticsService'
import { FirebaseAuthContext } from '../../contexts/AuthProvider'
import { useCollection } from 'react-firebase-hooks/firestore'
import { firestore } from 'firebase/app'
import { showToast } from '../../config/toast'
import Table, { ColumnData, RowData } from '../../components/table'
import LoadingSplash from '../../components/loading-splash'
import { HatView } from './hatView'

// const projectsColumns: ColumnData[] = [
//   {
//     id: 'client',
//     title: 'Client',
//     type: 'string',
//     sortable: true
//   },
//   {
//     id: 'service',
//     title: 'Service',
//     type: 'string',
//     sortable: true
//   },
//   {
//     id: 'contract',
//     title: 'contract',
//     type: 'string',
//     sortable: true
//   },
//   {
//     id: 'remaining',
//     title: 'Remaining',
//     type: 'string',
//     sortable: true
//   },
//   {
//     id: 'carryover',
//     title: 'Carry Over',
//     type: 'number',
//     sortable: true
//   },
//   {
//     id: 'goal',
//     title: 'Goal',
//     type: 'number',
//     sortable: true
//   },
//   {
//     id: 'used',
//     title: 'Used',
//     type: 'number',
//     sortable: true
//   },
//   {
//     id: 'adjustments',
//     title: 'Adjustments',
//     type: 'Array',
//     sortable: true
//   }
// ]
const monthTrans : any = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}
let getKeyByValue = (object : any, value : any) => {
  return Object.keys(object).find(key => object[key] === value);
}
const today = new Date();
const thisMonth = monthTrans[today.getMonth() + 1]
const thisYear = today.getFullYear();

// let adjustmentHtml = (adjustments:any[]) => {
//   // console.log('%c adjustmentHtml fired!', 'font-size: 18px; color: green;');
//   // console.log('adjustments = ', adjustments);
//   let combined = [];
//   let numberClass = '';
//   let count = 0;
//   for (const adjustment of adjustments) {
//     if(adjustment.amount) {
//       if(adjustment.amount < 0) {
//         numberClass = 'adjustment negative';
//       }else {
//         numberClass = 'adjustment';
//       }
//       combined.push(<div key={count} className={numberClass}>{adjustment.amount}</div>);
//     }
//     console.log('adjustment = ', adjustment);
//     count++;
//   }
//   return combined;
// }

// let getTimeFilter = () => {
//   let start = 2018
//   let fullMonths = 12
//   let finalMonth : any = 0;
  
//   let htmlToReturn = []
//   for(let year = start; year <= thisYear; year++) {
//     if(year != thisYear) {
//       finalMonth = fullMonths
//     }else {
//       finalMonth = getKeyByValue(monthTrans, thisMonth)
//     }
//     for(let monthLoop = 1; monthLoop <= finalMonth; monthLoop++) {
//       htmlToReturn.push(<option key={monthLoop.toString() + year.toString()} value={monthLoop + '/' + year}>{monthTrans[monthLoop]} {year} </option>);
//     }
//   }
//   return htmlToReturn;

// }

/**
 * Admin (projects) admin view
 * @TODO: Paginate
 * @TODO: Link to edit modal
 * @TODO: New project modal
 * @TODO: Service line filtering
 * @TODO: Proportional logos
 * @TODO: Reflect changes in TW?
 */
export const Hat: React.FC = () => {
  // let month = thisMonth
  // let year = 2019

  let filterControl = (e : any) => {
    let thisValue = e.target.value.split('/');
    // console.log('thisValue = ', thisValue);
    // let newMonth : any = getKeyByValue(monthTrans, thisValue[0])
    // console.log('newMonth = ', newMonth);
    setMonth(monthTrans[thisValue[0]])
    // month = thisValue[0]
    setYear(parseInt(thisValue[1]))
    sessionStorage.hatMonth = monthTrans[thisValue[0]];
    sessionStorage.hatYear = thisValue[1];

    // year = thisValue[1]
  
  }
  const [serviceLineCodesList, slLoading, slError] = useCollection(firestore().collection('hat_tool').doc('serviceCodes').collection('data'))

  const [month, setMonth] = useState<number>(sessionStorage.hatMonth || thisMonth)

  const [year, setYear] = useState<number>(sessionStorage.hatYear || thisYear);
  const [serviceLineCodes, setServiceLineCodes] = useState<any>({})


  useEffect(() => {
    let serviceLinesObject : any = {};
    if(slError) {
      // setSlIsLoading(false)
      showToast(JSON.stringify(slError.message), 'error')
    }else if(slLoading) {
      // setSlIsLoading(true);
    }else if(serviceLineCodesList) {
      // console.log('serviceLineCodesList = ', serviceLineCodesList);
      let codes = serviceLineCodesList.docs;
      codes.forEach((doc : any) => {
        const thisData = doc.data();
        // console.log('doc = ', doc);
        // console.log('thisData = ', thisData);
        serviceLinesObject[doc.id] = thisData.value;
      })
      
      setServiceLineCodes(serviceLinesObject);
      // setSlIsLoading(false);
      // console.log('serviceLineCodes is done');
      
      
    }
  }, [slError, slLoading, serviceLineCodesList])

  // const transformedMonth = getKeyByValue(monthTrans, month);
  // console.log('month = ', month);
  // console.log('serviceLineCodesList = ', serviceLineCodesList)
  // console.log('transformedMonth = ', transformedMonth);
  
  return <HatView month={monthTrans[month]} year={year} filterFunction={filterControl} serviceLineCodes={serviceLineCodes}/>
}
