import { app, auth, firestore, performance } from 'firebase/app'
import { functions } from 'firebase'
import 'firebase/auth'
import * as request from 'superagent'
import { teamworkInstallationName } from '../config/dynamic'
import { TeamworkAuthResponse, userDocData } from '../types/project'
import * as analytics from '../services/analyticsService'
import { flattenObject } from 'flatten-anything'

/**
 * Retrieves the current user's document data
 * @throws Error when the user has no doc
 */
export const getUserDoc = async (): Promise<userDocData | void> => {
  const trace = performance().trace('get-user-doc')
  trace.start()
  /**
   * Type-guard for user class
   */
  const user = auth().currentUser
  if (!user) return

  /**
   * Get the document and return the data
   */
  const docRef = app().firestore().collection('users').doc(user.uid)
  const currentDoc = await docRef.get()
  if (!currentDoc.exists) throw new Error('User not recorded')
  trace.stop()
  return currentDoc.data() as userDocData
}

/**
 * Updates the current user doc with provided data
 * @param data The data to insert
 * @throws Error when there is no user
 * @throws Error when the user has no doc
 */
export const updateUserDoc = async (data: userDocData): Promise<void> => {
  const trace = performance().trace('set-user-doc')
  trace.start()
  /**
   * Check if there is a logged in user and get their document reference
   */
  const user = auth().currentUser
  if (!user) throw new Error('No user logged in')
  const docRef = firestore().doc(`users/${user.uid}`)

  /**
   * Updating a non-existent document is a no go
   */
  if (!(await docRef.get()).exists) throw new Error('User not recorded')

  /**
   * Pass back the promise
   */
  await docRef.update(flattenObject(data))
  trace.stop()
}

/**
 * Update or create a user's doc based on their firebase auth profile
 */
export const recordLogin = async () => {
  console.log('login')
  const trace = performance().trace('record-login')
  trace.start()
  /**
   * Type-guard for user class
   */
  const currentUser = auth().currentUser
  if (!currentUser) return

  /**
   * Check if the user doc exists
   */
  const userDocRef = app().firestore().collection('users').doc(currentUser.uid)
  const userDocData = await userDocRef.get()
  const userDataData = userDocData.data() as undefined | userDocData
  const hasDoc = userDocData.exists

  /**
   * Update analytics
   */
  analytics.setUserID(currentUser.uid)
  analytics.logEvent(hasDoc ? 'login' : 'sign_up' as string, {})

  /**
   * Build the user data object and create or update the user document
   */
  const userData: userDocData = {}
  userData.preferences = {}
  if (currentUser.phoneNumber) userData.phoneNumber = currentUser.phoneNumber
  if (currentUser.displayName) userData.preferences.displayName = currentUser.displayName
  if (currentUser.photoURL) userData.preferences.photoURL = currentUser.photoURL
  if (currentUser.uid) userData.uid = currentUser.uid
  if (currentUser.email) userData.email = currentUser.email
  if (currentUser.emailVerified) userData.emailVerified = currentUser.emailVerified
  if (!hasDoc) userData.preferences.analyticsEnabled = true
  if (!hasDoc) userData.preferences.performanceEnabled = true
  if (hasDoc) {
    if (userDataData?.preferences) {
      if (!Object.keys(userDataData.preferences).includes('analyticsEnabled')) {
        userData.preferences.analyticsEnabled = true
      }
      if (!Object.keys(userDataData.preferences).includes('performanceEnabled')) {
        userData.preferences.performanceEnabled = true
      }
    } else {
      userData.preferences.analyticsEnabled = true
      userData.preferences.performanceEnabled = true
    }
  }
  userData.lastLogin = Date.now()
  userData.firstLogin = userDataData?.firstLogin || Date.now()
  await (hasDoc ? userDocRef.update(flattenObject(userData)) : userDocRef.set(userData))
  trace.stop()
}

/**
 * Firebase login with a pop-up
 * @param provider Auth provider class for the auth method
 * @param persist If true, remembers over sessions. default false, log out on browser close
 * @rejects if the login fails
 */
export const providerLoginPopup = async (provider: auth.AuthProvider, persist?: boolean): Promise<void> => {
  const trace = performance().trace('provider-login-popup')
  trace.putAttribute('provider', provider.providerId)
  trace.start()
  /**
   * Set auth persistence from the param or default to session
   */
  await auth().setPersistence(persist ? auth.Auth.Persistence.LOCAL : auth.Auth.Persistence.SESSION)

  /**
   * Trigger the auth pop-up and log the event
   */
  await auth().signInWithPopup(provider)
  await recordLogin()
  analytics.logEvent('log_in', { method: provider.providerId })
  trace.stop()
}

/**
 * Trade a teamwork Oauth code in for an access token
 * @param code
 */
export const connectToTeamwork = async (code: string): Promise<void> => {
  const trace = performance().trace('teamwork-connect')
  trace.start()
  /**
   * Call teamwork with the auth code
   */
  const response = await request
    .post('https://www.teamwork.com/launchpad/v1/token.json')
    .set({ 'content-type': 'application/json' })
    .send({ code })

  /**
   * Parse the response
   * Check if the installation name is the same as trinity's
   */
  const body = response.body as TeamworkAuthResponse
  if (body.installation.name !== teamworkInstallationName) {
    analytics.logEvent('exception', {
      fatal: false,
      description: 'Wrong teamwork site'
    })
    throw new Error('Wrong teamwork site connected')
  }

  /**
   * The user has logged in with the correct teamwork domain
   * add their token to the user doc
   */
  await updateUserDoc({ teamworkToken: body.access_token })
  await refreshTeamwork()
  trace.stop()
}

/**
 * Call the teamwork API through a cloud function proxy
 * The function will add updated teamwork data to the user document
 * @throws Error if the cloud function fails
 */
export async function refreshTeamwork (): Promise<void> {
  const trace = performance().trace('teamwork-refresh')
  trace.start()
  /**
   * get the teamwork token
   */
  const userDocRef = firestore().doc(`users/${auth().currentUser?.uid}`)
  const currentDoc = (await userDocRef.get()).data() as userDocData

  /**
   * Call the cloud function
   */
  await functions().httpsCallable('twpassthrough')({ teamworkToken: currentDoc.teamworkToken })
  trace.stop()
}

/**
 * Check if the current authorized user has connected the specified service
 * @param serviceName The name of the service to check for
 */
export function userHasService (serviceName: string): boolean {
  /**
   * Get a list of the user's provider and check for membership
   */
  const providers = auth().currentUser?.providerData
  if (providers) for (const provider of providers) if (provider?.providerId === serviceName) return true
  return false
}

/**
 * Send a password reset email to the specified email address
 * @param emailAddress
 * @param options Redirect options and such
 */
export async function sendPasswordResetEmail (emailAddress: string, options?: auth.ActionCodeSettings): Promise<void> {
  const trace = performance().trace('send-password-reset')
  trace.putAttribute('email', emailAddress)
  trace.start()
  await auth().sendPasswordResetEmail(emailAddress, options)
  trace.stop()
}

/**
 * Create the google auth provider
 */
export const GoogleProvider = new auth.GoogleAuthProvider()
GoogleProvider.setCustomParameters({
  prompt: 'select_account',
  hd: 'trinityinsight.com'
})

export const signOut = async (): Promise<void> => {
  const trace = performance().trace('sign-out')
  trace.putAttribute('user', auth().currentUser?.uid || 'unknown')
  trace.start()
  await auth().signOut()
  trace.stop()
}
