// https://github.com/fkhadra/react-toastify
import { toast, ToastContainerProps } from 'react-toastify'
import * as analytics from '../services/analyticsService'

export const toastConfig: ToastContainerProps = {
  position: 'bottom-right',
  draggable: true,
  draggablePercent: 60
}

export const showToast = (content: string, toastType: 'info' | 'success' | 'warning' | 'error' | 'default') => {
  toast(content, {
    type: toastType,
    onOpen: () => {
      analytics.logEvent(
        'alert-show',
        {
          message: content,
          type: toastType
        }
      )
    },
    onClick: () => {
      analytics.logEvent(
        'alert-click',
        {
          message: content,
          type: toastType
        }
      )
    },
    onClose: () => {
      analytics.logEvent(
        'alert-close',
        {
          message: content,
          type: toastType
        }
      )
    }
  })
}
