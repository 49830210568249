import React, { useContext } from 'react'
import Calendar from '../components/calendar'
import '../assets/scss/calendar-view.scss'

/**
 * Roadmap dashboard view
 * @constructor
 */
export const CalendarView: React.FC = () => {
  return (
    <div className='calendar-view view'>
      <h1>Calendar</h1>
      <div className='view-content'>
        <div className='card'>
          <div className='card-content'>
            <Calendar></Calendar>
          </div>
        </div>
      </div>
    </div>
  )
}
