import { Plugins } from '@capacitor/core'

const { Storage } = Plugins

async function setObject (key: string, object: any): Promise<void> {
  await Storage.set({
    key: key,
    value: JSON.stringify(object)
  })
}

async function getObject (key: string): Promise<any> {
  const ret = await Storage.get({ key: key })
  if (ret.value) {
    return JSON.parse(ret.value)
  } else {
    return null
  }
}

async function setItem (key: string, item: string) {
  await Storage.set({
    key: key,
    value: item
  })
}

async function getItem (key: string): Promise<string | null> {
  const { value } = await Storage.get({ key: key })
  return value
}

async function removeItem (key: string): Promise<void> {
  await Storage.remove({ key: key })
}

async function keys (): Promise<string[]> {
  const { keys } = await Storage.keys()
  return keys
}

async function clear (): Promise<void> {
  await Storage.clear()
}

export {
  setObject,
  getObject,
  setItem,
  getItem,
  removeItem,
  keys,
  clear
}
