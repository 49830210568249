import React, { useContext } from 'react'
import '../assets/scss/hours.scss'
import * as analytics from '../services/analyticsService'
import { FirebaseAuthContext } from '../contexts/AuthProvider'

/**
 * Data dashboard view
 */
export const Data: React.FC = () => {
  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'data'
  })
  analytics.setCurrentScreen('data')
  const { currentUser } = useContext(FirebaseAuthContext)
  if (currentUser?.uid) analytics.setUserID(currentUser.uid)

  return <div className='data view'>
    <h1>Data</h1>
    <div className='view-content'>
      <h3>Coming Soon</h3>
    </div>
  </div>
}
