import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import '../../assets/scss/modal.scss'
import * as analytics from '../../services/analyticsService'
import { FirebaseAuthContext } from '../../contexts/AuthProvider'
import { useCollection } from 'react-firebase-hooks/firestore'
import { firestore } from 'firebase/app'
import { showToast } from '../../config/toast'

const monthTrans : any = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}
let getKeyByValue = (object : any, value : any) => {
  return Object.keys(object).find(key => object[key] === value);
}
interface LoadingProps {
  modalOpen: any,
  content?: any,
  type?: string
}

interface iAdjustment {
  amount: number,
  code: string,
  description: string,
  isServiceLineExchange: boolean,
  linkedAdjustment: string,
  reason: string,
  serviceLineExchanged: string,
  timestamp: any,
  type: string,
  userRequesting: string
}

/**
 * Admin (projects) admin view
 * @TODO: Paginate
 * @TODO: Link to edit modal
 * @TODO: New project modal
 * @TODO: Service line filtering
 * @TODO: Proportional logos
 * @TODO: Reflect changes in TW?
 */
export const Modal: React.FC<LoadingProps> = (props) => {

  const [adjustmentObject, setAdjustmentObject] = useState<iAdjustment>({
    amount: 0,
    code: '',
    description: '',
    isServiceLineExchange: false,
    linkedAdjustment: '',
    reason: '',
    serviceLineExchanged: '',
    timestamp: '',
    type: '',
    userRequesting: ''
  })
  console.log('%c Modal fired!', 'font-size: 18px; color: green;');
  const closeModal = () => {
    props.modalOpen(false);
  }
  const applyChanges = () => {
    // Changes are done, time to close the modal
    closeModal();
  }
  return (
    <div className="modal-container">
      <div className="overlay" onClick={closeModal}></div>
      <div className="modal">
        <div className="modal-header">
          <h2>{props.type} an Adjustment</h2>
          <span onClick={closeModal}>X</span>
        </div>
        {props.content}
        <div className="modal-footer">
          <a onClick={closeModal}>Cancel</a>
          <a onClick={applyChanges}>Apply {props.type == 'edit' ? 'Edit' : 'Adjustment '}</a>
        </div>
      </div>
    </div>
  )
  
}