import React from 'react'
import '../assets/scss/loading.scss'

interface LoadingProps {
  caption?: string
}

const LoadingSplash: React.FC<LoadingProps> = (props) => {
  return (
    <div className="loader-page">
      <div className="loader">
        <div className="inner one"/>
        <div className="inner two"/>
        <div className="inner three"/>
      </div>

      <h2 className='caption'>{props.caption || 'Loading'}</h2>
    </div>
  )
}
export default LoadingSplash

// TODO: Set some kind of minimum display time so we don't flash this crap
