import React, { useContext, useEffect } from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { Sidebar } from '../components/sidebar'
import { Header } from '../components/header'
import ProjectProvider from '../contexts/ProjectProvider'
import { FirebaseAuthContext } from '../contexts/AuthProvider'
import DashboardProvider, { DashboardContext } from '../contexts/DashboardProvider'
import { dashboardRoutes } from '../routes'
import * as analytics from '../services/analyticsService'

export const DashboardLayout: React.FC<RouteComponentProps> = (props) => {
  const { dispatchToast, currentUser } = useContext(FirebaseAuthContext)

  const switchRoutes = (
    <Switch>
      {dashboardRoutes.map((prop, key) => {
        if (prop.redirect && prop.to) return <Redirect from={prop.path} to={prop.to} key={key}/>
        if (!prop.component) return <div/>
        return <Route path={prop.path} component={prop.component} key={key}/>
      })}
    </Switch>
  )

  useEffect(() => {
    if (currentUser?.uid) analytics.setUserID(currentUser.uid)
  }, [dispatchToast, props.location, currentUser])

  const { ...rest } = props
  return (
    <ProjectProvider>
      <DashboardProvider>
        <DashboardContext.Consumer>
          {({ visible, collapsed, setVisible }) => {
            return <div className={(collapsed) ? 'collapsed' : ''}>
              <div className={`overlay${visible ? ' menu-open' : ''}`} onClick={() => setVisible(false)}/>
              <Sidebar routes={dashboardRoutes} {...rest} class={visible ? ' menu-open' : ''}/>
              <Header/>
              <div>
                {switchRoutes}
              </div>
            </div>
          }}
        </DashboardContext.Consumer>
      </DashboardProvider>
    </ProjectProvider>
  )
}
