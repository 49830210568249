import React, { useContext, useEffect, useState } from 'react'
import '../../assets/scss/admin-home.scss'
import * as analytics from '../../services/analyticsService'
import { FirebaseAuthContext } from '../../contexts/AuthProvider'
import { useCollection } from 'react-firebase-hooks/firestore'
import { firestore } from 'firebase/app'
import { showToast } from '../../config/toast'
import Table, { ColumnData, RowData } from '../../components/table'
import LoadingSplash from '../../components/loading-splash'
import { AddUserModal } from '../../components/add-user-modal'

const userColumns: ColumnData[] = [
  {
    id: 'displayName',
    title: 'Name',
    type: 'string',
    sortable: true
  },
  {
    id: 'email',
    title: 'Email Address',
    type: 'string',
    sortable: true
  },
  {
    id: 'lastLogin',
    title: 'Last Logged In',
    type: 'string',
    sortable: true
  },
  {
    id: 'teamworkCompany',
    title: 'Teamwork Company',
    type: 'string',
    sortable: true
  },
  {
    id: 'uid',
    title: 'User ID',
    type: 'string',
    sortable: true
  }
]

/**
 * Admin (users) admin view
 * @TODO: Paginate
 * @TODO: Link to edit modal
 * @TODO: New user modal
 */
export const Users: React.FC = () => {
  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'admin-users'
  })
  analytics.setCurrentScreen('admin-users')
  const { currentUser } = useContext(FirebaseAuthContext)
  if (currentUser?.uid) analytics.setUserID(currentUser.uid)

  const [values, loading, error] = useCollection(firestore().collection('users'))
  const [usersRows, setUsersRows] = useState<RowData[]>([])
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const [modal, setModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  useEffect(() => {
    if (error) {
      showToast(JSON.stringify(error.message), 'error')
      setIsLoading(false)
    } else if (loading) {
      setIsLoading(true)
    } else if (values) {
      const userRowsMapped: RowData[] = []
      values.forEach((doc: any) => {
        const user = doc.data()
        userRowsMapped.push({
          data: {
            displayName: user.displayName,
            email: user.email,
            lastLogin: user.lastLogin
              ? new Date(user.lastLogin).toLocaleDateString() + ' ' + new Date(user.lastLogin).toLocaleTimeString()
              : <i>never</i>,
            teamworkCompany: user.teamworkCompany,
            uid: user.uid
          }
        })
      })
      setUsersRows(userRowsMapped)
      setIsLoading(false)
    }
  }, [error, loading, values])

  return <div className='users view'>
    <h3>Users <i onClick={() => { setModal(!modal) }} className={`fas ${modal ? 'fa-times' : 'fa-user-plus'}`}/></h3>
    {
      (modal
        ? <AddUserModal/>
        : <></>)
    }
    {
      (isLoading
        ? <LoadingSplash caption={'Loading users list'}/>
        : <Table columns={userColumns} rows={usersRows} filters={[]}/>)
    }
  </div>
}
