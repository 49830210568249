import React, { useContext } from 'react'
import VisualizerRow from '../components/visualizer-row'
import '../assets/scss/visualizer.scss'
import * as analytics from '../services/analyticsService'
import { FirebaseAuthContext } from '../contexts/AuthProvider'

/**
 * Visualizer dashboard view
 */
export const Visualizer: React.FC = () => {
  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'visualizer'
  })
  analytics.setCurrentScreen('visualizer')
  const { currentUser } = useContext(FirebaseAuthContext)
  if (currentUser?.uid) analytics.setUserID(currentUser.uid)

  return (
    <div className='visualizer view'>
      <h1>Visualizer</h1>
      <div className='view-content'>
        <div className='visualizer-header'>
          Available Templates
          <div className='schematic-view-toggle'/>
          <div className='list-view-toggle'/>
        </div>
        <div className='card'>
          <div className='card-content'>
            <VisualizerRow open={true} icon='global-elements' title='Global Elements' updateNum={2}/>
          </div>
        </div>
        <div className='card'>
          <div className='card-content'>
            <VisualizerRow open={false} icon='home-page' title='Home Page' updateNum={1}/>
          </div>
        </div>
        <div className='card'>
          <div className='card-content'>
            <VisualizerRow open={false} icon='product-listing-page' title='Product Listing Page' updateNum={2}/>
          </div>
        </div>
        <div className='card'>
          <div className='card-content'>
            <VisualizerRow open={false} icon='add-to-cart-modal' title='Add to Cart Modal' updateNum={2}/>
          </div>
        </div>
        <div className='card'>
          <div className='card-content'>
            <VisualizerRow open={false} icon='cart-page' title='Cart Page' updateNum={1}/>
          </div>
        </div>
        <div className='card'>
          <div className='card-content'>
            <VisualizerRow open={false} icon='checkout-page' title='Checkout Page' updateNum={2}/>
          </div>
        </div>
        <div className='card'>
          <div className='card-content'>
            <VisualizerRow open={false} icon='account-login' title='Account Login' updateNum={0}/>
          </div>
        </div>
        <div className='card'>
          <div className='card-content'>
            <VisualizerRow open={false} icon='sales-page' title='Sales Page' updateNum={1}/>
          </div>
        </div>
      </div>
    </div>
  )
}
