import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import '../../assets/scss/modal.scss'
import * as analytics from '../../services/analyticsService'
import { FirebaseAuthContext } from '../../contexts/AuthProvider'
import { useCollection } from 'react-firebase-hooks/firestore'
import { firestore } from 'firebase/app'
import { showToast } from '../../config/toast'

const monthTrans : any = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}
let getKeyByValue = (object : any, value : any) => {
  return Object.keys(object).find(key => object[key] === value);
}
interface LoadingProps {
  timeLog?: any
}
/**
 * Admin (projects) admin view
 * @TODO: Paginate
 * @TODO: Link to edit modal
 * @TODO: New project modal
 * @TODO: Service line filtering
 * @TODO: Proportional logos
 * @TODO: Reflect changes in TW?
 */
export const CreateAdjustment: React.FC<LoadingProps> = (props) => {
  console.log('props.timeLog = ', props.timeLog);
  return (
    <div className="modal-body">
      <div className="input-section">
        <select>
          <option>Get the Guy</option>
          <option>All the other Partners</option>
        </select>
      </div>
      <div className="input-section">
        <select>
          <option>UX</option>
          <option>Data</option>
          <option>Email</option>
          <option>Marketing</option>
          <option>Platforms</option>
          <option>SEO</option>
        </select>
      </div>
      <div className="input-section">
        <label>Add adjustment amount:</label>
        <input type="number" placeholder="00.00" />
      </div>
      <div className="input-section">
        <label>Select adjustment reason:</label>
        <select>
          <option>Gift to Partner</option>
          <option>Service Line Exchange</option>
          <option>Purchased Hours</option>
          <option>Other</option>
        </select>
      </div>
      <div className="input-section">
        <label>Description</label>
        <textarea placeholder="Reason for this adjustment"></textarea>
      </div>
    </div>
  )
  
}