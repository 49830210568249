import React from 'react'
import ChartistGraph from 'react-chartist'
import '../assets/scss/hours-data.scss'

interface HoursDataState {
  date: Date
}

class HoursData extends React.Component {
  readonly state: HoursDataState

  constructor (props: any) {
    super(props)
    this.state = { date: new Date() } as HoursDataState
  }

  render () {
    const date = new Date()
    const monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const thisMonth = monthArray[date.getMonth()]
    let lastMonthValue = date.getMonth() - 1

    if (lastMonthValue < 0) {
      lastMonthValue = 11
    }

    const lastMonth = monthArray[lastMonthValue]
    const hoursGoal = 60
    const hoursUsed = hoursGoal - 20
    const hoursUsedPercentage = Math.round(hoursUsed / hoursGoal * 100)
    const hoursRemaining = Math.round(hoursGoal - hoursUsed)

    const hoursData = {
      series: [
        {
          value: hoursUsed,
          name: 'Hours Used',
          className: 'hours-used'
        },
        {
          value: hoursRemaining,
          name: 'Hours Remaining',
          className: 'hours-remaining'
        }
      ]
    }

    const hoursOptions = {
      donut: true,
      donutWidth: 10,
      total: hoursGoal,
      donutSolid: true,
      startAngle: 0,
      showLabel: false
    }

    return (
      <div className='hours-data'>
        <h3>{thisMonth} Hours</h3>
        <div className='hours-info'>
          <div className='hours-contracted'>Contracted Monthly Hours<span className="hours-value">60.00</span></div>
          <div className='hours-carried-over'>Carry-Over from {lastMonth}<span className="hours-value">12.25</span>
          </div>
          <div className='hours-total'>Total Hours for {thisMonth}:<span className="hours-value">72.25</span></div>
        </div>

        <div className='hours-chart'>
          <div className='hours-pie'>
            <ChartistGraph data={hoursData} options={hoursOptions} type={'Pie'}/>
            <div className="percentage">{hoursUsedPercentage}%<span>Used</span></div>
          </div>

          <div className="key">
            <div>
              <p className="key-color remaining">Hours Left</p>
              <p className="hour-value">10</p>
            </div>

            <div>
              <p className="key-color used">Hours Used</p>
              <p className="hour-value">42.5</p>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default HoursData
