import React, { useContext } from 'react'
import Table from '../components/table'
import { GetUxDevData, UxColumns } from '../services/uxService'
import '../assets/scss/roadmap.scss'
import * as analytics from '../services/analyticsService'
import { FirebaseAuthContext } from '../contexts/AuthProvider'

const UxRoadmapColumns = [
  UxColumns.name,
  UxColumns.notes,
  UxColumns.testArea,
  UxColumns.progressionRate,
  UxColumns.progressionGoal
]

/**
 * Roadmap dashboard view
 * @constructor
 */
export const Roadmap: React.FC = () => {
  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'roadmap'
  })
  analytics.setCurrentScreen('roadmap')
  const { currentUser } = useContext(FirebaseAuthContext)
  if (currentUser?.uid) analytics.setUserID(currentUser.uid)

  return (
    <div className='roadmap view'>
      <h1>Roadmap</h1>
      <div className='view-content'>
        <div className='card'>
          <div className='card-content'>
            <Table columns={UxRoadmapColumns} rows={GetUxDevData()} filters={[]}/>
          </div>
        </div>
      </div>
    </div>
  )
}
