// import { TableProps, ColumnData, RowData, TableFilter } from '../components/table'

/**
 * These are the area options for a test to run on
 */
export type UxTestArea =
| 'Global'
| 'Landing Page'
| 'Navigation'
| 'Header/Footer'
| 'Homepage'
| 'Product Listing Page'
| 'Product Page'
| 'Add to Cart Modal'
| 'Cart Page'
| 'Cart/Checkout'
| 'Checkout Page'
| 'Account Login'
| 'Sales Page'

/**
 * The status of a UX Test
 */
export type UxTestStatus = 'Active' | 'Hosted' | 'Paused' | 'Past'

/**
 * The possible start date groups of a test
 */
export type UxTestStartRange =
| 'This Month'
| 'Last Month'
| 'This Year'
| 'Last Year'
| '2017'
| '2016'
| '2015'
| '2014'

export interface UXTestData {
  /** The VWO test ID */
  id: number
  /** The name of the test */
  name: string
  notes: string
  /** The epoch value of the test start date */
  startDate: number
  /** The filterable date range for this test */
  startRange: UxTestStartRange
  /** The current status of this test */
  status: UxTestStatus
  /** The test area specified in the test doc */
  testArea: UxTestArea
  /** A value of 0-100 representing the amount of test data collected */
  percentCollected: number
  progressionRate: number
  progressionGoal: number
  /** A link to the report for this test */
  reportLink: string

}

export const UxDevData: UXTestData[] = [
  {
    id: 145,
    name: 'Mobile Select Size Price',
    notes: 'Do this, do that, make the page much better',
    startDate: 1554076800000,
    startRange: 'This Year',
    status: 'Hosted',
    testArea: 'Product Page',
    percentCollected: 97,
    progressionRate: 45,
    progressionGoal: 50,
    reportLink: 'https://goo.gl/WtDFwj'
  },
  {
    id: 147,
    name: 'Holiday Homepage Banner Test',
    notes: 'Do this, do that, make the page much better',
    startDate: 1575158400000,
    startRange: 'This Month',
    status: 'Active',
    testArea: 'Homepage',
    percentCollected: 5,
    progressionRate: 45,
    progressionGoal: 50,
    reportLink: 'https://goo.gl/dfDRR'
  },
  {
    id: 146,
    name: 'Black Friday Sale Test',
    notes: 'Do this, do that, make the page much better',
    startDate: 1573516800000,
    startRange: 'Last Month',
    status: 'Active',
    testArea: 'Product Listing Page',
    percentCollected: 40,
    progressionRate: 45,
    progressionGoal: 50,
    reportLink: 'https://goo.gl/Cercec'
  },
  {
    id: 140,
    name: 'Mobile Homepage Hero',
    notes: 'Do this, do that, make the page much better',
    startDate: 1546300800000,
    startRange: 'This Year',
    status: 'Hosted',
    testArea: 'Homepage',
    percentCollected: 100,
    progressionRate: 45,
    progressionGoal: 50,
    reportLink: 'https://goo.gl/sdfCE'
  },
  {
    id: 139,
    name: 'Checkout Remove Sign In',
    notes: 'Do this, do that, make the page much better',
    startDate: 1542067200000,
    startRange: 'Last Year',
    status: 'Paused',
    testArea: 'Checkout Page',
    percentCollected: 26,
    progressionRate: 45,
    progressionGoal: 50,
    reportLink: 'https://goo.gl/DArdc'
  }
]

export const GetUxDevData = () => {
  const rows = []
  for (const record of UxDevData) {
    rows.push({
      data: record
    })
  }
  return rows
}

export const UxColumns = {
  name: {
    id: 'name',
    title: 'Test Name',
    type: 'String',
    sortable: true
  },
  notes: {
    id: 'notes',
    title: 'Notes & Details',
    type: 'String',
    sortable: false
  },
  startDate: {
    id: 'startDate',
    title: 'Start Date',
    type: 'Date',
    sortable: true
  },
  status: {
    id: 'status',
    title: 'Status',
    type: 'Status',
    sortable: true
  },
  testArea: {
    id: 'testArea',
    title: 'Site Area',
    type: 'Category',
    sortable: true
  },
  percentCollected: {
    id: 'percentCollected',
    title: 'Percent Collected',
    type: 'Chart',
    sortable: true
  },
  progressionRate: {
    id: 'progressionRate',
    title: 'Progression Rate',
    type: 'Chart',
    sortable: true
  },
  progressionGoal: {
    id: 'progressionGoal',
    title: 'Target',
    type: 'Chart',
    sortable: true
  },
  reportLink: {
    id: 'reportLink',
    title: 'Report',
    type: 'Link',
    sortable: false
  }
}
