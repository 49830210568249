import React, { useEffect, useState } from 'react'
import '../assets/scss/add-user-modal.scss'
import { useCollection } from 'react-firebase-hooks/firestore'
import { firestore } from 'firebase'
import { showToast } from '../config/toast'
import LoadingSplash from './loading-splash'

export interface ProjectSelectValues {
  name: string
  displayName: string
}

/**
 * Add user modal component for the admin page
 */
export const AddUserModal: React.FC = () => {
  const [projectValues, setProjectValues] = useState<ProjectSelectValues[]>([])
  const [values, loading, error] = useCollection(firestore().collection('projects'))
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (error) {
      setIsLoading(false)
      showToast(JSON.stringify(error.message), 'error')
    } else if (loading) {
      setIsLoading(true)
    } else if (values) {
      const projectsMapped: ProjectSelectValues[] = []
      values.forEach((doc: any) => {
        const project = doc.data()
        projectsMapped.push({ name: project.project, displayName: project.name })
        setIsLoading(false)
      })
      setProjectValues(projectsMapped)
    }
  }, [error, loading, values])

  return isLoading
    ? <LoadingSplash caption={'Loading'}/>
    : (<div className='add-user'>
      <h4 className='add-user-header'>Add User</h4>
      <div className='add-user-body'>
        <div className='card'>
          <div className='card-content'>
            <div className=' one-third'><h5>1. Select Project</h5></div>
            <div className=' two-thirds'>
              <select className='project-select' defaultValue=''>
                <option value='' disabled>Select a Project</option>
                {
                  projectValues.map((project, key) => {
                    return <option key={key} value={project.name}>{project.displayName}</option>
                  })
                }
              </select>
            </div>
          </div>
        </div>
        <div className='card'>
          <div className='card-content'>
            <div className=' one-third'><h5>2. Account Type</h5></div>
            <div className=' two-thirds'>
              <select className='project-select'>
                <option>Editor</option>
                <option>Viewer</option>
              </select>
            </div>
          </div>
        </div>
        <div className='card'>
          <div className='card-content'>
            <div className=' one-third'><h5>3. Personal</h5></div>
            <div className=' two-thirds'>
              <input placeholder={'name'}/>
              <input placeholder={'email'}/>
            </div>
          </div>
        </div>
      </div>
    </div>)
}
