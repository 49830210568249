import React, { useContext } from 'react'
import '../assets/scss/sidebar.scss'
import { Link } from 'react-router-dom'
import { DashboardContext } from '../contexts/DashboardProvider'
import { RouteItem } from '../types/generic'

interface SidebarProps {
  location: {
    pathname: string
  }
  routes: RouteItem[]
  class?: string
}

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const activeRoute = (routeName: string) => props.location.pathname === routeName ? 'active' : ''
  const { setCollapsed, setVisible } = useContext(DashboardContext)

  const links = props.routes.map((prop, key) => {
    /**
     * filter out redirects, and non-sidebar links
     */
    if (prop.redirect || !prop.name || !prop.linkClass) return <div key={key}/>
    return (
      <div className={`sidebar-link-parent ${activeRoute(prop.path)}`} key={key}>
        <Link
          className={`sidebar-link ${prop.linkClass}`}
          onClick={() => setVisible(false)}
          to={prop.path}>
          {prop.name}
        </Link>
      </div>
    )
  })

  return <div className={`sidebar${props.class || ''}`}>
    <div className='sidebar-logo-parent'>
      <Link onClick={() => setVisible(false)} className='sidebar-logo' to='/app/dashboard'/>
    </div>
    {links}
    <div className='sidebar-toggle' onClick={() => setCollapsed()}/>
  </div>
}
