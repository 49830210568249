import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { FirebaseAuthContext } from '../contexts/AuthProvider'
import { RouteItem } from '../types/generic'

import { DashboardLayout } from '../layouts/dashboard'
import { LoginLayout } from '../layouts/login'
import { Dashboard } from '../views/dashboard'
import { TestsView } from '../views/test-status'
import { Contact } from '../views/contact'
import { Roadmap } from '../views/roadmap'
import { Hours } from '../views/hours'
import { CalendarView } from '../views/calendar-view'
import { Visualizer } from '../views/visualizer'
import { MyAccount } from '../views/my-account'
import { AdminLayout } from '../layouts/admin'
import { Home } from '../views/admin/home'
import { Users } from '../views/admin/users'
import { Projects } from '../views/admin/projects'
import { Roles } from '../views/admin/roles'
import { Xray } from '../views/admin/xray'
import { Data } from '../views/data'
import { Hat } from '../views/admin/hat'

/**
 * Top level routes
 * Should point only to LAYOUTS in the layouts directory
 */
export const indexRoutes: RouteItem[] = [
  {
    path: '/app',
    component: DashboardLayout,
    requireAuth: true
  },
  {
    path: '/login',
    component: LoginLayout,
    requireAuth: false
  },
  {
    path: '/admin',
    component: AdminLayout,
    requireAuth: true
  },
  { redirect: true, path: '/', to: '/app' }
]

export class PrivateRoute extends Route {
  render () {
    const { ...rest } = this.props
    return (
      <FirebaseAuthContext.Consumer>
        {({ isUserSignedIn }) => {
          if (isUserSignedIn) return <Route {...rest}/>
          return <Redirect push to={{
            pathname: '/login',
            state: {
              redirect: this.props.location
                ? this.props.location.pathname
                : '/app'
            }
          }}/>
        }}
      </FirebaseAuthContext.Consumer>
    )
  }
}

export const dashboardRoutes: RouteItem[] = [
  {
    path: '/app/dashboard',
    component: Dashboard,
    linkClass: 'dashboard-link',
    name: 'Dashboard'
  },
  {
    path: '/app/tests',
    component: TestsView,
    linkClass: 'test-status-link',
    name: 'Test Status'
  },
  {
    path: '/app/roadmap',
    component: Roadmap,
    linkClass: 'roadmap-link',
    name: 'Roadmap'
  },
  {
    path: '/app/visualizer',
    component: Visualizer,
    linkClass: 'visualizer-link',
    name: 'Visualizer'
  },
  {
    path: '/app/hours',
    component: Hours,
    linkClass: 'hours-link',
    name: 'Hours'
  },
  {
    path: '/app/contact',
    component: Contact,
    linkClass: 'contact-link',
    name: 'Contact Us'
  },
  {
    path: '/app/calendar',
    component: CalendarView,
    linkClass: 'calendar-link',
    name: 'Calendar'
  },
  {
    path: '/app/data',
    component: Data,
    linkClass: 'data-link',
    name: 'Data'
  },
  {
    path: '/app/my-account',
    component: MyAccount
  },
  {
    redirect: true,
    path: '/app',
    to: '/app/dashboard'
  }
]

export const adminRoutes: RouteItem[] = [
  {
    path: '/admin/home',
    component: Home,
    linkClass: 'home-link',
    name: 'Admin Home'
  },
  {
    path: '/admin/users',
    component: Users,
    linkClass: 'users-link',
    name: 'Users'
  },
  {
    path: '/admin/projects',
    component: Projects,
    linkClass: 'projects-link',
    name: 'Projects'
  },
  {
    path: '/admin/roles',
    component: Roles,
    linkClass: 'roles-link',
    name: 'Roles'
  },
  {
    path: '/admin/xray',
    component: Xray,
    linkClass: 'xray-link',
    name: 'Xray'
  },
  {
    path: '/admin/hat',
    component: Hat,
    linkClass: 'hat-link',
    name: 'Hat Tool'
  },
  {
    redirect: true,
    path: '/admin',
    to: '/admin/home'
  }
]
