import React from 'react'
import '../assets/scss/test-counter.scss'

interface TestCounterProps {
}

const TestCounter: React.FC<TestCounterProps> = (props) => {
  return (
    <div className='test-counter'>
        <h2>Test Counter:</h2>
        <div className='test-section'>
            <p>Tests in Planning</p>
            <div className='test-num planning'>2</div>
        </div>
        <div className='test-section'>
            <p>Running Tests</p>
            <div className='test-num running'>3</div>
        </div>
        <div className='test-section'>
            <p>Hosted Tests</p>
            <div className='test-num hosted'>12</div>
        </div>
        <div className='test-section'>
            <p>Tests to Date</p>
            <div className='test-num to-date'>28</div>
        </div>
    </div>
  )
}
export default TestCounter
