/**
 * If user isn't in a test and has analytics enabled, pass function to sdk
 */
import { analytics, auth } from 'firebase/app'
import { getObject } from './storageService'

const logEvent = (eventName: string, eventParams: object, _options?: analytics.AnalyticsCallOptions): void => {
  if (process.env.NODE_ENV !== 'test') {
    if (auth().currentUser) {
      getObject('userDoc').then((data) => {
        if (data) {
          if (data.preferences) {
            if (data.preferences.analyticsEnabled) {
              analytics().logEvent(eventName, _options)
            }
          } else {
            analytics().logEvent(eventName, _options)
          }
        } else {
          analytics().logEvent(eventName, _options)
        }
      })
    } else {
      analytics().logEvent(eventName, _options)
    }
  }
}

const setCurrentScreen = (screenName: string, _options?: analytics.AnalyticsCallOptions): void => {
  if (process.env.NODE_ENV !== 'test') {
    if (auth().currentUser) {
      getObject('userDoc').then((data) => {
        if (data) {
          if (data.preferences) {
            if (data.preferences.analyticsEnabled) {
              analytics().setCurrentScreen(screenName, _options)
            }
          } else {
            analytics().setCurrentScreen(screenName, _options)
          }
        } else {
          analytics().setCurrentScreen(screenName, _options)
        }
      })
    } else {
      analytics().setCurrentScreen(screenName, _options)
    }
  }
}

const setUserProperties = (properties: analytics.CustomParams, _options?: analytics.AnalyticsCallOptions): void => {
  if (process.env.NODE_ENV !== 'test') {
    if (auth().currentUser) {
      getObject('userDoc').then((data) => {
        if (data) {
          if (data.preferences) {
            if (data.preferences.analyticsEnabled) {
              analytics().setUserProperties(properties, _options)
            }
          } else {
            analytics().setUserProperties(properties, _options)
          }
        } else {
          analytics().setUserProperties(properties, _options)
        }
      })
    } else {
      analytics().setUserProperties(properties, _options)
    }
  }
}

const setAnalyticsCollectionEnabled = (_enabled: boolean): void => {
  if (process.env.NODE_ENV !== 'test') {
    analytics().setAnalyticsCollectionEnabled(_enabled)
  }
}

const setUserID = (id: string, _options?: analytics.AnalyticsCallOptions): void => {
  if (process.env.NODE_ENV !== 'test') {
    if (auth().currentUser) {
      getObject('userDoc').then((data) => {
        if (data) {
          if (data.preferences) {
            if (data.preferences.analyticsEnabled) {
              analytics().setUserId(id, _options)
            }
          } else {
            analytics().setUserId(id, _options)
          }
        } else {
          analytics().setUserId(id, _options)
        }
      })
    } else {
      analytics().setUserId(id, _options)
    }
  }
}

export {
  logEvent,
  setCurrentScreen,
  setUserProperties,
  setAnalyticsCollectionEnabled,
  setUserID
}
