import React, { useContext } from 'react'
import HoursData from '../components/hours-data'
import '../assets/scss/hours.scss'
import * as analytics from '../services/analyticsService'
import { FirebaseAuthContext } from '../contexts/AuthProvider'

/**
 * Hours dashboard view
 */
export const Hours: React.FC = () => {
  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'hours'
  })
  analytics.setCurrentScreen('hours')
  const { currentUser } = useContext(FirebaseAuthContext)
  if (currentUser?.uid) analytics.setUserID(currentUser.uid)

  return <div className='hours view'>
    <h1>Hours</h1>
    <div className='view-content'>
      <div className='card one-quarter'>
        <div className='card-content'>
          <HoursData/>
        </div>
      </div>
      <div className='card three-quarters'>
        <div className='card-content'>
          <h3>December Hours Breakdown</h3>
        </div>
      </div>
    </div>
  </div>
}
