import React, { useContext, useState } from 'react'
import '../../assets/scss/admin-home.scss'
import * as analytics from '../../services/analyticsService'
import { FirebaseAuthContext } from '../../contexts/AuthProvider'
import { CameraResultType, Plugins } from '@capacitor/core'

/**
 * Admin (home) admin view
 */
export const Home: React.FC = () => {
  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'admin-home'
  })
  analytics.setCurrentScreen('admin-home')
  const { currentUser } = useContext(FirebaseAuthContext)
  if (currentUser?.uid) analytics.setUserID(currentUser.uid)

  const { Camera, Geolocation } = Plugins
  const [photo, setPhoto] = useState()
  const [location, setLocation] = useState('')

  const takePhoto = async () => {
    // @ts-ignore
    await Camera.requestPermissions()
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri
    })
    setPhoto(image.webPath)
  }

  const show = async () => {
    const loc = await Geolocation.getCurrentPosition()
    console.log(loc)
    setLocation(JSON.stringify(loc.coords))
  }

  return <div className='admin view'>
    <h3>Admin Home</h3>
    <img src={photo} alt={'device capture'}/>
    <button onClick={takePhoto}>Take Photo</button>

    <hr/>
    <p>{location}</p>
    <button onClick={show}>Get Location</button>
  </div>
}
