import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import '../../assets/scss/admin-hat-tool.scss'
import * as analytics from '../../services/analyticsService'
import { FirebaseAuthContext } from '../../contexts/AuthProvider'
import { useCollection } from 'react-firebase-hooks/firestore'
import { firestore } from 'firebase/app'
import { showToast } from '../../config/toast'
import Table, { ColumnData, RowData } from '../../components/table'
import LoadingSplash from '../../components/loading-splash'
import ReactTooltip from 'react-tooltip'
import { Modal } from './modal'
import { CreateAdjustment } from './createAdjustment'
import { EditAdjustment } from './editAdjustment'

const projectsColumns: ColumnData[] = [
  {
    id: 'client',
    title: 'Client',
    type: 'string',
    sortable: true
  },
  {
    id: 'service',
    title: 'Service',
    type: 'string',
    sortable: true
  },
  {
    id: 'contract',
    title: 'contract',
    type: 'string',
    sortable: true
  },
  {
    id: 'remaining',
    title: 'Remaining',
    type: 'string',
    sortable: true
  },
  {
    id: 'carryover',
    title: 'Carry Over',
    type: 'number',
    sortable: true
  },
  {
    id: 'goal',
    title: 'Goal',
    type: 'number',
    sortable: true
  },
  {
    id: 'used',
    title: 'Used',
    type: 'number',
    sortable: true
  },
  {
    id: 'adjustments',
    title: 'Adjustments',
    type: 'Array',
    sortable: true
  },
  {
    id: 'addAdjustment',
    title: '',
    type: 'any',
    sortable: false
  }
]
const monthTrans : any = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}
let getKeyByValue = (object : any, value : any) => {
  return Object.keys(object).find(key => object[key] === value);
}
const today = new Date();
const realTimeMonth = monthTrans[today.getMonth() + 1]
const realTimeYear = today.getFullYear();

// let filterControl = (e : any) => {
//   let thisValue = e.target.value;
//   console.log('thisValue = ', thisValue);
// }
/**
 * Admin (projects) admin view
 * @TODO: Paginate
 * @TODO: Link to edit modal
 * @TODO: New project modal
 * @TODO: Service line filtering
 * @TODO: Proportional logos
 * @TODO: Reflect changes in TW?
 */
interface LoadingProps {
  year: number,
  month?: string,
  filterFunction?: any,
  serviceLineCodes?: any
}

interface iRawData {
  id: string,
  client: string,
  service: string,
  contract: number,
  remaining: number,
  carryover: number,
  goal: number,
  used: number,
  adjustments: []
}

export const HatView: React.FC<LoadingProps> = (props) => {

  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'admin-hat'
  })
  // const [currentProps, setCurrentProps] = useState<any>(props)
  analytics.setCurrentScreen('admin-hat')
  const { currentUser } = useContext(FirebaseAuthContext)
  if (currentUser?.uid) analytics.setUserID(currentUser.uid)
  // const [values, loading, error] = useCollection(firestore().collection('hat_tool').where('timelogs.year', '==', 2019))\
  // const [filterYear, setFilterYear] = useState<number>(2019)
  // const [filterMonth, setFilterMonth] = useState<number>(today.getMonth() + 1)
  // console.log('currentProps.month = ', currentProps.month);
  // let transformedMonth = getKeyByValue(monthTrans, currentProps.month);
  // console.log('transormedMonth = ', transformedMonth);
  
  // console.log('props.month = ', props.month)
  let filterMonth = props.month || sessionStorage.hatMonth || 'January'
  let filterYear = props.year || sessionStorage.hatYear || 2019
  let filterDefaultValue = getKeyByValue(monthTrans, filterMonth) + '/' + filterYear;
  const [values, loading, error] = useCollection(firestore().collection('hat_tool').doc('timelogs').collection('data').where('month', '==', filterMonth).where('year', '==', parseInt(filterYear)));
  // const [serviceLineCodesList, slLoading, slError] = useCollection(firestore().collection('hat_tool').doc('serviceCodes').collection('data'))
  // const [serviceLineCodesList, setServiceLineCodesList] = useState<any>();
  const [projectRows, setProjectRows] = useState<RowData[]>([])
  const [rawData, setRawData] = useState<iRawData[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [slIsLoading, setSlIsLoading] = useState<boolean>(true)
  const [serviceLineCodes, setServiceLineCodes] = useState<any>({})
  const [tooltip, setTooltip] = useState<any>('')
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [modalLogId, setModalLogId] = useState<string>('')
  const [activeAdjustmentId, setActiveAdjustmentId] = useState<string>('')
  const [adjustmentType, setAdjustmentType] = useState<string>('')

  if(props.serviceLineCodes && Object.keys(props.serviceLineCodes).length && !Object.keys(serviceLineCodes).length) {
    setServiceLineCodes(props.serviceLineCodes);
  }

  const getTimeFilter = () => {
    let start = 2018
    let fullMonths = 12
    let finalMonth: any = 0;
    let selected : boolean = false;
    
    let htmlToReturn = []
    for(let year = start; year <= realTimeYear; year++) {
      if(year != realTimeYear) {
        finalMonth = fullMonths
      }else {
        finalMonth = getKeyByValue(monthTrans, realTimeMonth)
      }
      for (let monthLoop = 1; monthLoop <= finalMonth; monthLoop++) {
        if (monthTrans[monthLoop] == filterMonth && year == filterYear) {
          selected = true
        } else {
          selected = false;
        }
          htmlToReturn.push(<option key={monthLoop.toString() + year.toString()} value={monthLoop + '/' + year}>{monthTrans[monthLoop]} {year} </option>);
      }
    }
    return htmlToReturn;
  
  }

  const adjustmentHtml = (adjustments:any[], id: string) => {
    // console.log('%c adjustmentHtml fired!', 'font-size: 18px; color: green;');
    // console.log('adjustments = ', adjustments);
    let adjustmentMessage = ''
    let combined = [];
    let numberClass = '';
    let count = 0;
    for (const adjustment of adjustments) {
      if(adjustment.amount) {
        if(adjustment.amount < 0) {
          numberClass = 'adjustment negative';
        }else {
          numberClass = 'adjustment';
        }
        adjustmentMessage = adjustment.description+'<br />Click adjustment to edit';
        combined.push(<div key={count}><a data-log-id={id} onClick={editAdjustment} data-tip="" data-for={adjustment.code} className={numberClass}> {adjustment.amount}</a> <ReactTooltip place="top" effect="solid" id={adjustment.code}><span className="multi-line">{adjustment.description}</span><span className="multi-line edit-message">Click adjustment to Edit</span></ReactTooltip></div>);
      }
      // console.log('adjustment = ', adjustment);
      count++;
    }
    return combined;
  }

  const addAdjustment = (e : any) => {
    setAdjustmentType('create')
    setModalLogId(e.target.getAttribute('data-log-id'))
    modalControl();
  }

  const editAdjustment = (e : any) => {
    setAdjustmentType('edit')
    setModalLogId(e.target.getAttribute('data-log-id'))
    setActiveAdjustmentId(e.target.getAttribute('data-for'))
    modalControl();
  }

  const modalControl = () => {
    setModalActive(!modalActive);
  }

  useEffect(() => {
    // console.log('serviceLineCodesList = ', serviceLineCodesList);
    if (error) {
      setIsLoading(false)
      showToast(JSON.stringify(error.message), 'error')
    } else if (loading ) {
        setIsLoading(true)
    } else if (values) {
      const projectRowsMapped: RowData[] = []
      const rawDataMapped: iRawData[] = []
      // console.log('values = ', values);
      // setTimeout(() => {
        values.forEach((doc: any) => {
          const projects = doc.data()
          // console.log('doc = ', doc);
          // console.log('projects = ', projects);
          // if(typeof projects === 'object' && projects !== null && Array.isArray(projects.data)) {
            // for(let project of projects.data) {
              projectRowsMapped.push({
                data: {
                  id: doc.id,
                  client: projects.company_name_on_entry,
                  service: serviceLineCodes[projects.service_line_code],
                  contract: projects.contract,
                  remaining: (projects.hoursRemaining < 0) ? <span className="negative">{projects.hoursRemaining}</span> : projects.hoursRemaining,
                  carryover: (projects.carryover < 0) ? <span className="negative">{projects.carryover}</span> : projects.carryover,
                  goal: projects.goal,
                  used: projects.hoursUsed,
                  adjustments: adjustmentHtml(projects.adjustments, doc.id),
                  addAdjustment: <span data-log-id={doc.id} onClick={addAdjustment} className="add-adjustment">Add</span>,
                }
              })
              rawDataMapped.push({
                  id: doc.id,
                  client: projects.company_name_on_entry,
                  service: serviceLineCodes[projects.service_line_code],
                  contract: projects.contract,
                  remaining: projects.hoursRemaining,
                  carryover: projects.carryover,
                  goal: projects.goal,
                  used: projects.hoursUsed,
                  adjustments: projects.adjustments
                })
            // }
          // }
        })
      
      setProjectRows(projectRowsMapped)
      setRawData(rawDataMapped)
        // setTimeout(() => {
        setIsLoading(false)
      // }, 3000);
    }
  }, [error, loading, values, serviceLineCodes])
  // useEffect(() => {
  //   let serviceLinesObject : any = {};
  //   if(slError) {
  //     setSlIsLoading(false)
  //     showToast(JSON.stringify(slError.message), 'error')
  //   }else if(slLoading) {
  //     setSlIsLoading(true);
  //   }else if(serviceLineCodesList) {
  //     // console.log('serviceLineCodesList = ', serviceLineCodesList);
  //     let codes = serviceLineCodesList.docs;
  //     codes.forEach((doc : any) => {
  //       const thisData = doc.data();
  //       // console.log('doc = ', doc);
  //       // console.log('thisData = ', thisData);
  //       serviceLinesObject[doc.id] = thisData.value;
  //     })
      
  //     setServiceLineCodes(serviceLinesObject);
  //     setSlIsLoading(false);
  //     // console.log('serviceLineCodes is done');
      
      
  //   }
  // }, [slError, slLoading, serviceLineCodesList])
  // console.log('filterDefaultValue = ', filterDefaultValue);
  // console.log('serviceLineCodes = ', serviceLineCodes);
  let modalContent : any = ''
  let modalTimelogObject : any = '';
  
  if(modalActive) {
    console.log('rawData = ', rawData);
    for(let log of rawData) {
      // console.log('log = ', log);
      if(log.id == modalLogId) {
        modalTimelogObject = log
        break;
      }
    }
    if(adjustmentType == 'edit') {
      modalContent = <EditAdjustment timeLog={modalTimelogObject} adjustmentCode={activeAdjustmentId}/>
    }else {
      modalContent = <CreateAdjustment timeLog={modalTimelogObject}/>
    }
  }
  return (
    <div className='users view'>
      <h3>HAT (Hours Allocation Tool)</h3>
      Select Date <select value={filterDefaultValue} onChange={props.filterFunction}>{getTimeFilter()}</select>
      {
        isLoading
          ? <LoadingSplash caption={'Loading Timelogs list'}/>
          : <Table columns={projectsColumns} rows={projectRows} filters={[]}/>
      }
      {
        modalActive
        ? <Modal modalOpen={setModalActive} content={modalContent} type={adjustmentType} />
        : ''
      }
    </div>
  )
}
