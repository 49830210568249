import React from 'react'
import '../assets/scss/calendar.scss'

interface CalendarProps {
}

const Calendar: React.FC<CalendarProps> = (props) => {
  return (
    <div className='calendar'>
      <div className='month'>
        <div className='prev-month'><span></span></div>
        <div className='current-month'>January 2020</div>
        <div className='next-month'><span></span></div>
      </div>
      <div className='weekdays'>
        <div className='day'>Sun</div>
        <div className='day'>Mon</div>
        <div className='day'>Tue</div>
        <div className='day'>Wed</div>
        <div className='day'>Thu</div>
        <div className='day'>Fri</div>
        <div className='day'>Sat</div>
      </div>
      <div className='week'>
        <div className='day'><span></span></div>
        <div className='day'><span>1</span></div>
        <div className='day'><span>2</span></div>
        <div className='day'><span>3</span></div>
        <div className='day'><span>4</span></div>
        <div className='day'><span>5</span></div>
        <div className='day'><span>6</span></div>
      </div>
      <div className='week'>
        <div className='day'><span>7</span></div>
        <div className='day'><span>8</span></div>
        <div className='day today'><span>9</span></div>
        <div className='day'><span>10</span></div>
        <div className='day'><span>11</span></div>
        <div className='day'><span>12</span></div>
        <div className='day'><span>13</span></div>
      </div>
    </div>
  )
}
export default Calendar
