import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { LoginView } from '../views/login'
import { GoogleProvider, providerLoginPopup, recordLogin } from '../services/userService'
import { analytics, auth } from 'firebase/app'
import { showToast } from '../config/toast'
import { FirebaseAuthContext } from '../contexts/AuthProvider'

interface InterfaceProps {
  history?: any;
}

export const LoginLayout: React.FC<InterfaceProps> = () => {
  const { isUserSignedIn } = React.useContext(FirebaseAuthContext)
  const loginError = () => { showToast('There was a problem logging in', 'error') }
  const location = useLocation()

  const handleGoogleLogin = () => {
    providerLoginPopup(GoogleProvider)
      .then((result) => {
        console.log(result)
      })
      .catch(loginError)
  }

  const handlePasswordLogin = (email: string, password: string) => {
    auth().signInWithEmailAndPassword(email, password)
      .catch((error) => {
        const errorCode = error.code
        analytics().logEvent('exception', { fatal: false, description: errorCode })
        switch (errorCode) {
          case 'auth/wrong-password':
            showToast('Incorrect email or password', 'error')
            break
          case 'auth/user-not-found':
            showToast('Incorrect email or password', 'error')
        }
      })
      .then(
        () => analytics().logEvent('login', { method: 'password' })
      )
      .then(recordLogin)
  }

  if (isUserSignedIn) {
    return <Redirect push to={{
      // @ts-ignore
      pathname: location.state ? location.state.redirect : '/app',
      state: { from: '/login' }
    }}/>
  }
  return <LoginView
    loginWithGoogle={handleGoogleLogin}
    loginWithPassword={handlePasswordLogin}
  />
}
