import React, { useContext, useEffect, useState } from 'react'
import '../assets/scss/my-account.scss'
import * as analytics from '../services/analyticsService'
import { ProfileForm } from '../components/profile-form'
import { useLocation } from 'react-router-dom'
import * as queryString from 'query-string'
import { connectToTeamwork, GoogleProvider, updateUserDoc, userHasService } from '../services/userService'
import LoadingSplash from '../components/loading-splash'
import { FirebaseAuthContext } from '../contexts/AuthProvider'
import { User } from 'firebase/app'
import { showToast } from '../config/toast'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import { UpdatePhoto } from '../components/update-photo'

/**
 * My Account dashboard view
 * this view is not linked in the sidebar
 * @todo: remove route component props and replace with global
 * @todo: handle preferences updates
 */
export const MyAccount: React.FC = () => {
  /**
   * Set state and import functions and data from the auth context
   */
  const { userDoc, currentUser, sendPasswordReset } = useContext(FirebaseAuthContext)
  const cu = currentUser as unknown as User | null // Type forcing

  const location = useLocation()

  const [photoModal, setPhotoModal] = useState<boolean>(false)
  const toggleModal = () => setPhotoModal(!photoModal)

  const [loading, setLoading] = useState<boolean>(false)
  const [parsedCode, setParsedCode] = useState<boolean>(false)
  const [loadingMessage, setLoadingMessage] = useState<string>('Loading Account Information')
  const [analyticsEnabled, setAnalyticsEnabled] = useState<boolean>(!!userDoc.preferences?.analyticsEnabled)
  const [performanceEnabled, setPerformanceEnabled] = useState<boolean>(!!userDoc.preferences?.performanceEnabled)

  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'my-account'
  })
  analytics.setCurrentScreen('my-account')
  if (currentUser?.uid) analytics.setUserID(currentUser.uid)

  useEffect(() => {
    const parsed = queryString.parse(location.search)
    /**
     * Handle the processing of query params
     */
    if (parsed && !parsedCode) {
      setParsedCode(true)
      if (Object.keys(parsed).length > 0) {
        /**
         * The state property is a specific callback value
         * code will vary if other oauth flows are added
         */
        if (parsed.state && parsed.code) {
          if (parsed.state === 'teamwork') {
            setLoading(true)
            setLoadingMessage('Connecting to Teamwork')

            /**
             * Handle teamwork callback
             */
            connectToTeamwork(parsed.code as string)
              .then(() => showToast('Connected to Teamwork!', 'success'))
              .then(() => setLoading(false))
              .then(() => { analytics.logEvent('teamwork-add', {}) })
              .catch((err) => {
                setLoading(false)
                showToast('There was a problem connecting to Teamwork, please try again later or contact us',
                  'error')
                console.error(err)
                analytics.logEvent('exception',
                  { fatal: false, description: 'Failed to log into Teamwork' })
              })
          }
        }
      }
    }
  }, [location.search, parsedCode])

  useEffect(() => {
    let changed = false
    if (userDoc.preferences.analyticsEnabled !== analyticsEnabled ||
      userDoc.preferences.performanceEnabled !== performanceEnabled) {
      changed = true
    }
    if (changed) {
      updateUserDoc({
        preferences: {
          performanceEnabled: performanceEnabled,
          analyticsEnabled: analyticsEnabled
        }
      }).then(() => {
        showToast('Preferences Saved!', 'success')
      })
    }
  }, [performanceEnabled, analyticsEnabled, userDoc.preferences.performanceEnabled, userDoc.preferences.analyticsEnabled])

  /**
   * Remove a login method from the user's account specified by its providerId
   * @param serviceName Provider's providerId to remove
   */
  const unlinkService = (serviceName: string): void => {
    const user = currentUser as unknown as User | null
    if (!user) return
    if (userHasService(serviceName)) {
      setLoading(true)
      setLoadingMessage(`Removing ${serviceName}`)
      user.unlink(serviceName).then(() => {
        setLoading(false)
        showToast(`${serviceName} removed`, 'success')
      }).catch(() => {
        setLoading(false)
        showToast(`Could not remove ${serviceName}, please contact us for help.`, 'warning')
      })
    }
  }

  /**
   * Remove teamwork from the user's account
   * @todo move into user service and auth context
   */
  const teamworkRemove = (): void => {
    setLoadingMessage('Logging out of Teamwork')
    setLoading(true)
    updateUserDoc({
      teamworkToken: '',
      teamworkCompany: '',
      admin: false,
      teamworkUser: '',
      teamworkCompanyId: 0,
      teamworkName: '',
      teamworkEmail: ''
    })
      .then(() => {
        setLoading(false)
        showToast('Logged out of Teamwork', 'success')
        analytics.logEvent('teamwork-remove', {})
      })
      .catch((err) => {
        setLoading(false)
        console.error(err)
        showToast('Error logging out of teamwork', 'error')
        analytics.logEvent('exception', { fatal: false, description: 'Failed to log out of Teamwork' })
      })
  }

  // @ts-ignore
  return loading
    ? <><LoadingSplash caption={loadingMessage}/></>
    : <div className='account view'>
      {photoModal ? <UpdatePhoto toggleModal={toggleModal}/> : ''}
      <h1>My Account</h1>
      <div className='view-content'>
        <div className='card one-quarter'>
          <div className='card-content'>
            <h3>Teamwork</h3>
            {userDoc.teamworkToken
              ? <p className='connection-status connected'>Connected</p>
              : <p className='connection-status not-connected'>Not connected</p>}
            {userDoc.teamworkToken
              ? <span className='login-btn' onClick={() => {
                if (!window.confirm('This will remove your Teamwork account from your profile, and you will need to log in again to access your projects. Proceed?')) return
                teamworkRemove()
              }}>Sign Out</span>
              : <a className='login-btn' href={`https://www.teamwork.com/launchpad/login?redirect_uri=${
                window.location.href.split('?')[0]}?state=teamwork`}>Login</a>}
          </div>
        </div>
        <div className='card three-quarters'>
          <div className='card-content'>
            <ProfileForm updatePhoto={toggleModal}/>
          </div>
        </div>
        <div className='card two-thirds'>
          <div className='card-content'>
            <h3>Authentication</h3>
            <div className='auth google-auth'>
              <h3>Google Account</h3>
              {userHasService('google.com')
                ? <p
                  className='edit-link'
                  onClick={() => {
                    if (!window.confirm('This will remove your google account, and you will not be able to use it to log in anymore. Proceed?')) return
                    unlinkService('google.com')
                  }}>Disconnect</p>
                : <p
                  className='edit-link'
                  onClick={cu ? () => { cu.linkWithRedirect(GoogleProvider) } : () => {}}>Connect an
                  Account</p>}
              {userHasService('google.com')
                ? <p className='connection-status connected'>Connected</p>
                : <p className='connection-status not-connected'>Not connected</p>}
            </div>
            <div className='auth password'>
              <h3>Password</h3>
              <p className='edit-link edit' onClick={() => {
                if (!window.confirm('This will send a password reset link to your email address on file. Proceed?')) return
                setLoading(true)
                setLoadingMessage('Sending password reset email...')
                sendPasswordReset(currentUser?.email)
                  .finally(() => setLoading(false))
              }}>
                {userHasService('password')
                  ? 'Reset Password'
                  : 'Set a Password'}
              </p>
              {userHasService('password')
                ? <p className='connection-status connected'>Password Set</p>
                : <p className='connection-status not-connected'>Not Set</p>}
            </div>
          </div>
        </div>
        <div className='card one-third'>
          <div className='card-content'>
            <h3>Data Preferences</h3>
            <h5>Enabling this data collection helps us improve our app experience!</h5>
            <div>
              <Toggle
                id='analytics-status'
                checked={analyticsEnabled}
                onChange={() => setAnalyticsEnabled(!analyticsEnabled)}/>
              <label htmlFor='analytics-status'> Collect analytics data</label>
            </div>
            <div>
              <Toggle
                id='performance-status'
                checked={performanceEnabled}
                onChange={() => setPerformanceEnabled(!performanceEnabled)}/>
              <label htmlFor='performance-status'> Collect performance data</label>
            </div>
            <div>
              <button
                onClick={() => window.alert('Please email app@trinity.one with the subject line "Account Data" to' +
                  ' request your account data')}>Request account data
              </button>
              <button
                onClick={() => window.alert('Please email app@trinity.one with the subject line "Delete Account" to' +
                  ' request account deletion')}>Request account deletion
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
}
