import React, { useContext, useEffect, useState } from 'react'
import '../../assets/scss/admin-home.scss'
import * as analytics from '../../services/analyticsService'
import { FirebaseAuthContext } from '../../contexts/AuthProvider'
import { useCollection } from 'react-firebase-hooks/firestore'
import { firestore } from 'firebase/app'
import { showToast } from '../../config/toast'
import Table, { ColumnData, RowData } from '../../components/table'
import LoadingSplash from '../../components/loading-splash'

const userColumns: ColumnData[] = [
  {
    id: 'displayName',
    title: 'Name',
    type: 'string',
    sortable: true
  },
  {
    id: 'email',
    title: 'Email Address',
    type: 'string',
    sortable: true
  },
  {
    id: 'uid',
    title: 'User ID',
    type: 'string',
    sortable: true
  },
  {
    id: 'admin',
    title: 'Admin',
    type: 'Boolean',
    sortable: true
  }
]

/**
 * Admin (users) admin view
 * @TODO: Paginate
 * @TODO: Add or remove admin
 */
export const Roles: React.FC = () => {
  /**
   * Firebase analytics
   */
  analytics.logEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: 'admin-users'
  })
  analytics.setCurrentScreen('admin-users')
  const { currentUser } = useContext(FirebaseAuthContext)
  if (currentUser?.uid) analytics.setUserID(currentUser.uid)

  const [values, loading, error] = useCollection(firestore().collection('users'))
  const [usersRows, setUsersRows] = useState<RowData[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  useEffect(() => {
    if (error) {
      showToast(JSON.stringify(error.message), 'error')
      setIsLoading(false)
    } else if (loading) {
      setIsLoading(true)
    } else if (values) {
      const userRowsMapped: RowData[] = []
      values.forEach((doc: any) => {
        const user = doc.data()
        userRowsMapped.push({
          data: {
            displayName: user.displayName,
            email: user.email,
            uid: user.uid,
            admin: user.admin || false
          }
        })
      })
      setUsersRows(userRowsMapped)
      setIsLoading(false)
    }
  }, [error, loading, values])

  return <div className='users view'>
    <h3>Users</h3>
    {
      (isLoading
        ? <LoadingSplash caption={'Loading users list'}/>
        : <Table columns={userColumns} rows={usersRows} filters={[]}/>)
    }
  </div>
}
