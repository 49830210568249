import React, { useContext } from 'react'
import '../assets/scss/header.scss'
import { Link } from 'react-router-dom'
import { FirebaseAuthContext } from '../contexts/AuthProvider'
import { DashboardContext } from '../contexts/DashboardProvider'

export const Header: React.FC = () => {
  const { userDoc, signOut } = useContext(FirebaseAuthContext)
  const { setVisible } = useContext(DashboardContext)
  const displayName = userDoc?.preferences?.displayName?.toString()
  const image = userDoc?.customPhoto || userDoc?.preferences?.photoURL || undefined
  const admin = userDoc?.teamworkCompany === require('../config/dynamic').teamworkCompanyName
  return (
    <div className='header'>
      <div className='hamburger' onClick={() => setVisible()}><span/><span/><span/></div>
      <Link className='header-logo' to='/app'/>
      <div className='user-content'>
        <img src={image} className='user-img' alt="user profile"/>
        <div className='user'>{displayName} </div>
        <div className="dropdown-content">
          <Link to={'/app/my-account'}>My account</Link>
          {admin ? <Link to={'/admin'}>Admin</Link> : ''}
          <Link to={''} onClick={signOut}>Logout</Link>
        </div>
      </div>
    </div>
  )
}
