import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { FirebaseAuthContext } from '../contexts/AuthProvider'
import { Sidebar } from '../components/sidebar'
import { adminRoutes } from '../routes'
import ProjectProvider from '../contexts/ProjectProvider'
import DashboardProvider, { DashboardContext } from '../contexts/DashboardProvider'
import { Header } from '../components/header'

/**
 *
 * @todo: views
 */
export const AdminLayout: React.FC = () => {
  const { isUserSignedIn } = React.useContext(FirebaseAuthContext)
  const location = useLocation()

  if (!isUserSignedIn) {
    return <Redirect push to={{
      // @ts-ignore
      pathname: location.state ? location.state.redirect : '/login',
      state: { from: '/admin' }
    }}/>
  }

  const switchRoutes = (
    <Switch>
      {adminRoutes.map((prop, key) => {
        if (prop.redirect && prop.to) return <Redirect from={prop.path} to={prop.to} key={key}/>
        if (!prop.component) return <div/>
        return <Route path={prop.path} component={prop.component} key={key}/>
      })}
    </Switch>
  )

  return (
    <ProjectProvider>
      <DashboardProvider>
        <DashboardContext.Consumer>
          {({ visible, collapsed, setVisible }) => {
            return <div className={(collapsed) ? 'collapsed' : ''}>
              <div className={`overlay${visible ? ' menu-open' : ''}`} onClick={() => setVisible(false)}/>
              <Sidebar routes={adminRoutes} class={visible ? ' menu-open' : ''} location={location}/>
              <Header/>
              <div>
                {switchRoutes}
              </div>
            </div>
          }}
        </DashboardContext.Consumer>
      </DashboardProvider>
    </ProjectProvider>
  )
}
