import React from 'react'
import '../assets/scss/filter.scss'

interface FilterProps {
  filterStatus: number
  statusAction: (value: number) => void
}

/*
function filterVal (value: number, bit: number): boolean {
  return ((value >> bit) % 2 === 1)
}
*/

interface FilterState {
  statusOpen: boolean
  areaOpen: boolean
  startOpen: boolean
}

class Filter extends React.Component<FilterProps> {
  readonly state: FilterState

  constructor (props: FilterProps) {
    super(props)

    this.state = {
      statusOpen: false,
      areaOpen: false,
      startOpen: false
    }
  }

  getStatusValue (offset: number) {
    const change = ((this.props.filterStatus >> offset) % 2 === 1) ? (-1 * Math.pow(2, offset)) : Math.pow(2, offset)
    this.props.statusAction(change)
  }

  getStatusClass (offset: number) {
    return ((this.props.filterStatus >> offset) % 2 === 1) ? 'dropdown-option selected' : 'dropdown-option'
  }

  render () {
    return (
      <div className='filter'>
        <h3>Filter View:</h3>
        <div className={`dropdown ${this.state.statusOpen ? 'open' : ''}`}>
          <div
            className='dropdown-title'
            onClick={() => { this.setState({ statusOpen: !this.state.statusOpen }) }}> Test Status
          </div>
          <div className='dropdown-content'>
            <div className={`active-test ${this.getStatusClass(0)}`} onClick={() => this.getStatusValue(0)}>Active</div>
            <div className={`hosted-test ${this.getStatusClass(1)}`} onClick={() => this.getStatusValue(1)}>Hosted</div>
            <div className={`paused-test ${this.getStatusClass(2)}`} onClick={() => this.getStatusValue(2)}>Pause</div>
            <div className={`past-test ${this.getStatusClass(3)}`} onClick={() => this.getStatusValue(3)}>Past</div>
          </div>
        </div>
        <div className={`dropdown ${this.state.areaOpen ? 'open' : ''}`}>
          <div className='dropdown-title' onClick={() => { this.setState({ areaOpen: !this.state.areaOpen }) }}>Site
            Area
          </div>
          <div className='dropdown-content'>
            <div className='dropdown-option'/>
            <div className='dropdown-option'/>
            <div className='dropdown-option'/>
            <div className='dropdown-option'/>
          </div>
        </div>
      </div>
    )
  }
}

export default Filter
