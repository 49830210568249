import * as React from 'react'
import { auth, firestore, performance, User } from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import { getUserDoc, sendPasswordResetEmail, signOut } from '../services/userService'
import * as analytics from '../services/analyticsService'
import { userDocData } from '../types/project'
import { showToast } from '../config/toast'
import { setObject } from '../services/storageService'

const sendPasswordReset = async (email?: string | null): Promise<void> => {
  analytics.logEvent('password-reset-click', {})
  if (!email) if (!auth().currentUser) return
  const emailAddress = auth().currentUser?.email || email as string
  sendPasswordResetEmail(emailAddress, {
    url: window.location.href
  }).then(() => {
    showToast(`Password reset email sent to ${emailAddress}.`, 'success')
    analytics.logEvent('password-reset-sent', {})
  }).catch(() => {
    showToast('Could not send password reset email, please contact us for help.', 'error')
    analytics.logEvent('exception', { fatal: false, description: 'Password reset email failed' })
  })
}

const defaultContext = {
  authStatusReported: false,
  isUserSignedIn: false,
  userDoc: {
    preferences: {
      displayName: 'Trinity Partner'!,
      photoURL: ''!,
      analyticsEnabled: true,
      performanceEnabled: true
    },
    email: ''!,
    teamworkToken: ''!,
    teamworkCompany: ''!,
    customPhoto: ''!
  },
  currentUser: (Date.now() > 0) ? null : auth().currentUser,
  signOut: signOut,
  dispatchToast: (v?: boolean) => { console.log(v) },
  sendPasswordReset
}

export const FirebaseAuthContext = React.createContext(defaultContext)

type FireAuthProviderProps = {
  children: any
}

type FireAuthProviderState = {
  authStatusReported: boolean,
  isUserSignedIn: boolean,
  userDoc: userDocData,
  signOut: () => void,
  currentUser: User | null,
  dispatchToast: (v?: boolean) => void
}

export class FirebaseAuthProvider extends React.Component<FireAuthProviderProps, FireAuthProviderState> {
  state = defaultContext
  _isMounted = false

  componentDidMount () {
    this._isMounted = true
    auth().onAuthStateChanged(user => {
      if (this._isMounted) {
        this.setState({
          authStatusReported: true,
          isUserSignedIn: !!user
        })
        if (this.state.isUserSignedIn) {
          getUserDoc().then((res) => {
            this.setState({
              userDoc: res as userDocData,
              currentUser: auth().currentUser
            })
            setObject('userDoc', res).catch(() => analytics.logEvent('exception', {
              fatal: false,
              description: 'Failed to set local storage'
            }))
            if (res) {
              if (res.preferences) {
                if (Object.keys(res.preferences).includes('analyticsEnabled')) {
                  analytics.setAnalyticsCollectionEnabled(!!res.preferences.analyticsEnabled)
                }
                if (Object.keys(res.preferences).includes('performanceEnabled')) {
                  performance().dataCollectionEnabled = !!res.preferences.analyticsEnabled
                }
              }
            }
          })
        }
      }
      firestore().doc(`users/${auth().currentUser?.uid}`).onSnapshot({
        next: (snapshot: firestore.DocumentSnapshot): void => {
          if (this._isMounted) {
            const userData = snapshot.data() as userDocData | undefined
            if (userData) {
              this.setState({ userDoc: userData })
              analytics.setUserProperties({ company_name: userData?.teamworkCompany || 'none' })
              setObject('userDoc', userData).catch(() => analytics.logEvent('exception', {
                fatal: false,
                description: 'Failed to set local storage'
              }))
              if (userData.preferences) {
                if (Object.keys(userData.preferences).includes('analyticsEnabled')) {
                  analytics.setAnalyticsCollectionEnabled(!!userData.preferences.analyticsEnabled)
                }
                if (Object.keys(userData.preferences).includes('performanceEnabled')) {
                  performance().dataCollectionEnabled = !!userData.preferences.analyticsEnabled
                }
              }
            } else {
              getUserDoc()
                .then(doc => this.setState({ userDoc: doc as userDocData }))
            }
          }
        },
        error: (error: firestore.FirestoreError): void => {
          analytics.logEvent('exception', {
            fatal: false,
            description: error.code
          })
        }
      })
    })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { children } = this.props
    const { authStatusReported, isUserSignedIn, userDoc, signOut, dispatchToast, currentUser } = this.state
    return (
      <FirebaseAuthContext.Provider value={{
        isUserSignedIn,
        authStatusReported,
        userDoc,
        signOut,
        dispatchToast,
        currentUser,
        sendPasswordReset
      }}>
        {authStatusReported && children}
      </FirebaseAuthContext.Provider>
    )
  }
}

// FIXME: You can manually change the isUserSignedIn value of FirebaseAuthProvider to access the
//  dashboard. You can probably also then manipulate the values in the userDoc.
