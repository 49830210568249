import React, { useState } from 'react'
import '../assets/scss/visualizer-row.scss'

interface VisualizerRowProps {
  icon: string
  title: string
  updateNum: number
  open: boolean
}

const VisualizerRow: React.FC<VisualizerRowProps> = (props) => {
  const [isOpen, setOpen] = useState<boolean>(props.open)
  return (
    <div className={`visualizer-row ${isOpen ? 'open' : 'collapsed'}`}>
      <div className='visualizer-info'>
        <div className={`visualizer-title ${props.icon}`}>{props.title}</div>
        <div className='visualizer-updates'>{props.updateNum} update(s)</div>
        <div className='row-toggle' onClick={() => setOpen(!isOpen)}/>
      </div>
      <div className='dropdown-content'>
        <div className='relevant-docs'>Relevant Documents</div>
        <div className='template-row'>
          <div className='template'>
            <div className='visualizer-image'>
              <p>Control</p>
            </div>
            <p className='date'>Jun, 2019</p>
          </div>
          <div className='template'>
            <div className='visualizer-image'>
              <p>Navigation Redesign Test</p>
            </div>
            <p className='date'>Oct, 2019</p>
          </div>
          <div className='template'>
            <div className='visualizer-image'>
              <p>Global Shipping Prop Test - Geo Location</p>
            </div>
            <p className='date'>Nov, 2019</p>
          </div>
          <div className='template'>
            <div className='visualizer-image'>
              <p>Global Shipping Prop Test - Geo Location</p>
            </div>
            <p className='date'>Nov, 2019</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default VisualizerRow
