import * as React from 'react'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import { Project } from '../types/project'

const sampleProject: Project = {
  name: 'Sample Project',
  twId: 0,
  trinityContact: {
    firstName: 'Tim',
    lastName: 'Oesterlin',
    title: 'Lead Account Analyst'
  },
  testCounts: {
    total: 28,
    running: 6,
    hosted: 19
  }
}

const defaultContext = {
  projectDoc: sampleProject,
  projectID: ''
}

export const ProjectContext = React.createContext(defaultContext)

type ProjectProviderProps = {
  children: any
}

type ProjectProviderState = {
  projectDoc: Project
  projectID: string
}

export default class ProjectProvider extends React.Component<ProjectProviderProps, ProjectProviderState> {
  state = defaultContext

  render () {
    const { children } = this.props
    const { projectDoc, projectID } = this.state
    return (
      <ProjectContext.Provider value={{ projectDoc, projectID }}>
        {children}
      </ProjectContext.Provider>
    )
  }
}
