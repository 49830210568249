import * as React from 'react'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'

const defaultContext = {
  collapsed: false,
  visible: false,
  setCollapsed: (v?: boolean) => { console.log(v) },
  setVisible: (v?: boolean) => { console.log(v) }
}

export const DashboardContext = React.createContext(defaultContext)

type ProjectProviderProps = {
  children: any
}

const DashboardProvider: React.FC<ProjectProviderProps> = (props) => {
  const [collapsed, setCollapsed] = React.useReducer((s: any, v: any) => v || !s, defaultContext.collapsed)
  const [visible, setVisible] = React.useReducer((s: any, v: any) => v || !s, defaultContext.visible)

  const { children } = props
  return (
    <DashboardContext.Provider value={{ collapsed, setCollapsed, visible, setVisible }}>
      {children}
    </DashboardContext.Provider>
  )
}

export default DashboardProvider
