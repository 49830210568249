import React, { useCallback, useContext, useState } from 'react'
import '../assets/scss/update-photo.scss'
// https://github.com/react-dropzone/react-dropzone
import { DropEvent, useDropzone } from 'react-dropzone'
import { maxProfilePhotoSize, minProfilePhotoSize } from '../config/dynamic'
import { showToast } from '../config/toast'
import { FirebaseAuthContext } from '../contexts/AuthProvider'
import { storage } from 'firebase'

import AvatarEditor from 'react-avatar-editor'
import { updateUserDoc } from '../services/userService'

const cloudImage = require('../assets/img/cloud-upload.svg')

interface UpdatePhotoProps {
  toggleModal: () => void
}

export const UpdatePhoto: React.FC<UpdatePhotoProps> = (props) => {
  const [photo, setPhoto] = useState<any>()
  const [cropped, setCropped] = useState<Blob | undefined>()
  const [croppedUrl, setCroppedUrl] = useState<string>('')
  const { currentUser } = useContext(FirebaseAuthContext)
  const [crop, setCrop] = useState<number>()
  const [editor, setEditor] = useState<any>()

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: File[], event: DropEvent) => {
    if (acceptedFiles.length > 0) {
      setPhoto(Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0]) }))
    } else {
      if (rejectedFiles[0].size < minProfilePhotoSize || rejectedFiles[0].size > maxProfilePhotoSize) {
        showToast('Photo must be between 50kb and 5mb.', 'error')
      } else if (rejectedFiles[0].type.indexOf('image') !== 0) {
        showToast('Unsupported file type. Please select an image file.', 'error')
      } else {
        showToast('That didn\'t work, please try another photo or contact us.', 'error')
      }
    }
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ['image/png', 'image/jpg', 'image/bmp', 'image/gif'],
    multiple: false,
    maxSize: maxProfilePhotoSize,
    minSize: minProfilePhotoSize
  })

  const cropIt = async () => {
    const canvas = editor.getImage() as HTMLCanvasElement
    setCroppedUrl(canvas.toDataURL())
    canvas.toBlob((e) => { if (e) setCropped(e) })
  }

  const setRef = (e: any) => setEditor(e)

  const cropElement = photo
    // @ts-ignore
    ? <>
      <AvatarEditor
        image={photo.preview} borderRadius={100} scale={crop} ref={setRef} height={75} width={75}
        onPositionChange={cropIt}/>
      <input
        name="scale"
        type="range"
        onChange={(event) => {
          setCrop(parseFloat(event.target.value))
          cropIt().then(() => {})
        }}
        min="1"
        max="2"
        step="0.01"
        defaultValue="1"
      />
    </>
    : ('')

  const submitImage = async () => {
    const fileRef = storage().ref('user/' + currentUser?.uid + '/userImage/').child(Date.now().toString() + '.png')
    if (cropped) {
      await fileRef.put(cropped)
      const photoUrl = await fileRef.getDownloadURL()
      await updateUserDoc({ customPhoto: photoUrl })
    }
  }

  return (
    <div className={'update-photo'}>
      <div className={'curtain'} onClick={props.toggleModal}/>
      <div className={'update-photo-modal'}>
        <div className={'update-photo-header'}>
          <span className={'update-photo-title'}>Update Profile Image</span>
          <span
            onClick={props.toggleModal}
            className={'fa fa-times update-photo-close'}/>
        </div>
        {
          !!photo
            ? cropElement
            /* Clicking anything from the cloud down to browse files will trigger an open file dialog */
            : <div className={'update-photo-body'}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                  isDragActive
                    ? <div className={'drop'}>Drop File Here</div>
                    : <>
                      <img src={cloudImage} className={'update-photo-cloud'}/>
                      <div className={'update-photo-headline'}>Drag & Drop or Browse Files</div>
                    </>
                }
              </div>
            </div>
        }

        <div className={'action-buttons'}>
          <button className={'update-photo-cancel'} onClick={props.toggleModal}>Cancel</button>
          <button className={'update-photo-submit'} onClick={submitImage}>Update Image</button>
        </div>
      </div>
    </div>
  )
}
