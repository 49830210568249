/**
 * This file is for things that might change with a rebrand or other event
 */

export const teamworkInstallationName = 'Trinity'
export const teamworkCompanyName = 'Trinity'

/**
 * Profile picture upload size limits in bytes
 */

// 5mb
export const maxProfilePhotoSize = 5 * 1024 * 1024

// 5kb
export const minProfilePhotoSize = 50 * 1024
