import React, { useContext } from 'react'
import '../assets/scss/profile-form.scss'
import { FirebaseAuthContext } from '../contexts/AuthProvider'

const cameraIcon = require('../assets/img/camera.svg')

interface ProfileFormProps {
  updatePhoto: () => void
}

export const ProfileForm: React.FC<ProfileFormProps> = (props) => {
  const { userDoc } = useContext(FirebaseAuthContext)
  return <div className='user-profile'>
    <div className={'user-image-container'} onClick={props.updatePhoto}>
      {/* TODO: Pointer cursor on image hover */}
      <img src={userDoc?.customPhoto || userDoc?.preferences?.photoURL} className='user-img' alt="user profile"/>
      <div className="user-img-after"><img src={cameraIcon}/></div>
    </div>
    <div className='user-info'>
      <div><h3>Name: </h3><p>{userDoc?.preferences?.displayName}</p></div>
      <div><h3>Company Name: </h3><p>{userDoc?.teamworkCompany || 'Trinity Insight'}</p></div>
      <div><h3>Email: </h3><p>{userDoc?.email}</p></div>
    </div>
  </div>
}
